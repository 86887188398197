import React from 'react'

function Button(props) {
    return (
        <div>
            <button className='px-6 h-[50px] bg-gradient-to-r text-white text-[17px]  border-[#ff7133] hover:-translate-y-2 transition-all duration-500  font-semibold block from-[#ff3834] to-[#ff7133]  rounded border'>{props.name}</button>
        </div>
    )
}

export default Button;


