import React from 'react';
import { motion } from "framer-motion";
import { FeaturedServicesData } from '../utils/FeaturedServicesData';

function FeaturedServicesCard() {
    return (
        <section className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-10 h-full'>
            {FeaturedServicesData.map((item, index) =>
                <motion.div variants={item.variants} initial="hidden" whileInView={"visible"}  key={index} className='flex flex-col space-y-5'>
                    <img className='w-[50px] h-[50px] object-contain hover:-translate-y-2 transition-all duration-150' src={item.img1} />
                    <div className='text-[#01010] capitalize font-semibold text-lg'>{item.header}</div>
                    <p>{item.headerDescription}</p>
                </motion.div>
            )}
        </section>
    )
}

export default FeaturedServicesCard;