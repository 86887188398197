import React, { useContext, useState } from 'react';
import { motion } from "framer-motion";
import fadeIn from '../variants';
import BannersComponent from '../Constant/BannersComponent';
import { ContactData } from '../utils/ContactData';
import Button from '../Constant/Button';
import { BottomContent } from '../App';
import { PiMapPin } from 'react-icons/pi';
import { GiSmartphone } from 'react-icons/gi';
import { TfiEmail } from 'react-icons/tfi';
import Ban14 from '../Assert/Ban14-7.png';
import Home from '../Assert/Home5-15-7.png';
import emailjs from 'emailjs-com';



function Contact() {
    const UseBottomContext = useContext(BottomContent);
    const aboutCompany = [
        {
            img1: <PiMapPin className='text-[#104cba] text-5xl' />,
            header: "Our Location",
            headerDescription: "No 22, Padi Pudu Nagar, Main Road Anna Nagar West Ext, Chennai",
            variants: fadeIn("bottom", 0.2)
        },
        {
            img1: <GiSmartphone className='text-[#ff3834] text-5xl' />,
            header: "Phone Number",
            headerDescription: "+91 79047 04039",
            headerDescription1: "+91 99522 98666",
            variants: fadeIn("top", 0.3)
        },
        {
            img1: <TfiEmail className='text-[#7ae7f6] text-5xl' />,
            header: "Email Address",
            headerDescription: "info@illusionspotline.com",
            variants: fadeIn("bottom", 0.2),
            headerDescription1: "",
        },
    ]

    const [name, setName] = useState("");
    const [message, setMessage] = useState("");
    const [mobile_number, set_mobile_number] = useState("")
    const onChangeMobileNumber = (e) => {
        if (e.target.value.length <= 10) {
            set_mobile_number(e.target.value);
        }
        else {
            console.log(e.target.value)
        }
    }
    const AddTheQueries = async () => {
        let data = {
            "name": name,
            "mobileNumber": mobile_number,
            "message": message,
            "status": "Pending",
            "createdAt":
                new Date().getUTCFullYear() +
                "-" +
                (Number(new Date().getUTCMonth()) + 1) +
                "-" +
                new Date().getUTCDate() +
                "-" +
                new Date().getUTCHours() +
                "-" +
                new Date().getUTCMinutes() +
                "-" +
                new Date().getUTCSeconds(),
            "updatedAt":
                new Date().getUTCFullYear() +
                "-" +
                (Number(new Date().getUTCMonth()) + 1) +
                "-" +
                new Date().getUTCDate() +
                "-" +
                new Date().getUTCHours() +
                "-" +
                new Date().getUTCMinutes() +
                "-" +
                new Date().getUTCSeconds(),
        };
        emailjs.send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', data).then(
            function (response) {
                console.log('SUCCESS!', response.status, response.text);
            },
            function (err) {
                console.log('FAILED...', err);
            },
        )
    }
    return (
        <div ref={UseBottomContext} className='flex flex-col  '>
            <BannersComponent header="Contact Us" title="Contact Us" />
            <div className='py-16 overflow-hidden'>
                <div className='max-w-[1200px] mx-auto p-3'>
                    {ContactData.map((item, index) =>
                        <section key={index} className='flex flex-row lg:flex-nowrap flex-wrap items-center justify-between lg:gap-0 gap-8'>
                            <div className='lg:w-[48%] w-full'>
                                <motion.img variants={fadeIn("left", 0.1)} initial="hidden" whileInView={"visible"}  src={item.img} />
                            </div>
                            <div className='flex flex-col space-y-5 lg:w-[48%] w-full relative'>
                                <img src={Ban14} className='absolute left-1/2 -top-[20px]' />
                                <motion.div variants={fadeIn("bottom", 0.2)} initial="hidden" whileInView={"visible"}  className='text-lg font-semibold text-[#104cba]'>{item.company}</motion.div>
                                <motion.div variants={fadeIn("bottom", 0.4)} initial="hidden" whileInView={"visible"}  className='text-4xl capitalize font-semibold text-black'>we provide best services <br /> Need Help?</motion.div>
                                <motion.div variants={fadeIn("bottom", 0.6)} initial="hidden" whileInView={"visible"}  className='flex flex-col gap-3'>
                                    <input value={name} onChange={(e) => setName(e.target.value)} type='text' placeholder='Your Name *' className=' outline-none focus:border hover:border-[#104cba] transition-all duration-200 focus:border-[#104cba]' />
                                    <input value={mobile_number} onChange={(e) => onChangeMobileNumber(e)} type='number' maxLength={10} placeholder='Phone No *' className=' ' />
                                    <textarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder='Message *' className='textarea' />
                                    <a href='https://outlook.office365.com/mail/'>
                                        <Button name="Send Message" />
                                    </a>
                                </motion.div>
                                <img src={Home} className='absolute left-[70%] -bottom-[40px]' />
                            </div>
                        </section>)}
                </div>
            </div>
            <div className='py-16'>
                <div className='max-w-[1200px] mx-auto p-3'>
                    <div className='flex flex-col space-y-10'>
                        <div className='flex flex-col items-center justify-center space-y-5'>
                            <motion.div variants={fadeIn("bottom", 0.2)} initial="hidden" whileInView={"visible"}  className='text-lg font-semibold text-[#104cba]'>CONTACT US


                            </motion.div>
                            <motion.div variants={fadeIn("bottom", 0.4)} initial="hidden" whileInView={"visible"}  className='text-4xl self-center capitalize font-semibold text-black'>Let's Get Together and Work


                            </motion.div>
                            <motion.p variants={fadeIn("bottom", 0.6)} initial="hidden" whileInView={"visible"}  className='text-center'>Lets Take Our Conversation Ahead


                            </motion.p>
                        </div>
                    </div>
                    <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1  mt-10'>
                        {aboutCompany.map((item, index) =>
                            <motion.div variants={item.variants} initial="hidden" whileInView={"visible"}  key={index} className='flex flex-col items-center m-[0_15px] justify-center space-y-3 bg-white shadow-[1px_-2px_20px_12px_rgba(0,0,0,0.031)] p-[20px_40px] rounded-md transition-bg-border-radius-shadow duration-300'>
                                <div>{item.img1}</div>
                                <div className='text-base text-[#828282]'>{item.header}</div>
                                <p className='text-center text-black font-semibold'>{item.headerDescription}</p>
                                <p className='text-center text-black font-semibold'>{item.headerDescription1}</p>
                            </motion.div>
                        )}
                    </div>
                </div>
            </div>
            {/* {item.aboutCompany.map((item1, index1) =>
                                    <motion.div variants={item1.variants} initial="hidden" whileInView={"visible"}  key={index1} className='flex flex-row items-center space-x-5'>
                                        <div className=''>{item1.img1}</div>
                                        <div className='flex flex-col space-y-2'>
                                            <div className='text-base text-[#828282] font-normal'>
                                                {item1.header}
                                            </div>
                                            <div className='text-base text-black'>
                                                {item1.headerDescription}
                                            </div>
                                        </div>
                                    </motion.div>
                                )} */}
            {/* <div className='py-16 '>
                <motion.div variants={fadeIn("bottom", 0.2)} initial="hidden" whileInView={"visible"}  className='lg:max-w-[1200px] lg:mx-auto lg:p-16 p-5 w-full motion-effects-layer ' >
                    <div className='flex flex-col gap-5 w-full'>
                        <motion.div variants={fadeIn("bottom", 0.3)} initial="hidden" whileInView={"visible"}  className='text-white text-lg text-center'>Contact Us </motion.div>
                        <motion.div variants={fadeIn("bottom", 0.4)} initial="hidden" whileInView={"visible"}  className='text-white text-4xl not-italic font-semibold text-center capitalize'>Get in touch  </motion.div>
                        <motion.div variants={fadeIn("bottom", 0.5)} initial="hidden" whileInView={"visible"} >
                            <input type='text' placeholder='Your Name *' className='input ' />
                            <input type='text' placeholder='Your Email *' className='input ' />
                            <input type='number' maxLength={10} placeholder='Phone No *' className='input ' />
                            <input type='text' placeholder='Company *' className='input ' />
                            <textarea placeholder='Message *' className='textarea' />
                        </motion.div>
                        <motion.div variants={fadeIn("bottom", 0.6)} initial="hidden" whileInView={"visible"}  className='flex flex-col items-center justify-center'>
                            <Button name="Send Message" />
                        </motion.div>
                    </div>
                </motion.div>
            </div> */}

        </div>
    )
}

export default Contact;