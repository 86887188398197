// import React, { useState } from 'react';
// import { motion } from "framer-motion";
// import fadeIn from '../variants';
// import { GrFacebookOption } from "react-icons/gr";
// import { FaTwitter } from "react-icons/fa";
// import { FaPinterestP } from "react-icons/fa";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// function TeamMemberCard(props) {
//     const [showSocial, setShowSocial] = useState(-1);
//     const settings = {
//         dots: false,
//         speed: 2000,
//         slidesToShow: 4,
//         slidesToScroll: 1,
//         infinite: true,
//         autoplay: true,
//         autoplaySpeed: 1000,
//         arrows:false
//     };
//     return (
//         <Slider {...settings}>
//             {props.data.map((item, index) =>
//                 <div onPointerEnter={() => setShowSocial(index)} key={index} className='flex flex-col space-y-3 items-center justify-center  p-3 mt-4'>
//                     <div className='flex flex-col relative overflow-hidden'>
//                         <img className='object-contain rounded ' src={item.img} />
//                         {showSocial == index ?
//                             <motion.div className='Team absolute w-[80%] self-center flex flex-row items-center space-x-5 justify-center h-12 border z-40  bottom-[20px] rounded-lg border-none  bg-gradient-to-r hover:from-[#2B1489] hover:to-[#104DBC] transition-all duration-500 from-[#104DBC] to-[#2B1489]'>
//                                 <a href=''>
//                                     <GrFacebookOption className='text-white text-xl not-italic font-themif' />
//                                 </a>
//                                 <a href=''>
//                                     <FaTwitter className='text-white text-xl not-italic font-themif' />
//                                 </a>
//                                 <a href=''>
//                                     <FaPinterestP className='text-white text-xl not-italic font-themif' />
//                                 </a>
//                             </motion.div> : null}
//                     </div>
//                     <div className='text-lg text-black text-center capitalize not-italic font-semibold'>{item.name}</div>
//                     <p className='text-center'>{item.role}</p>
//                 </div>)}
//         </Slider>
//     )
// }

// export default TeamMemberCard;

// {/* <motion.div variants={fadeIn("bottom", 0.8)} initial="hidden" whileInView={"visible"}  className='flex flex-row gap-5'> */ }






import React, { useState } from 'react';
import { motion } from "framer-motion";
import fadeIn from '../variants';
import { GrFacebookOption } from "react-icons/gr";
import { FaTwitter } from "react-icons/fa";
import { FaPinterestP } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function TeamMemberCard(props) {
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", backgroundColor: "#2C0F84", width: 20, height: 20, borderRadius: 30 }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", backgroundColor: "#2C0F84", width: 20, height: 20, borderRadius: 30 }}
                onClick={onClick}
            />
        );
    }
    const settings = {
        dots: false,
        speed: 2000,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        arrows:false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <Slider {...settings}>
            {props.data.map((item, index) =>
                <div className='flex flex-col gap-4 items-center justify-between flex-none p-2'>
                    <div key={index} className="flex flex-col gap-2 items-center justify-between bg-white rounded-lg shadow-lg border-2  lg:h-[350px] h-[400px]">
                        <div className='flex flex-col h-[80%] overflow-hidden'>
                            <img className='w-full transition-all hover:scale-75 hover:bg-indigo-500 duration-1000  object-contain  rounded-t-lg' src={item.img} />
                        </div>
                        <div className='text-lg text-black text-center capitalize not-italic font-semibold h-[20%] flex flex-col items-center justify-center'>{item.title}</div>
                    </div>
                </div>)
            }
        </Slider >

    )
}

export default TeamMemberCard;

{/* <motion.div variants={fadeIn("bottom", 0.8)} initial="hidden" whileInView={"visible"}  className='flex flex-row gap-5'> */ }
{/* {showSocial == index ?
                            <motion.div className='Team absolute w-[80%] self-center flex flex-row items-center space-x-5 justify-center h-12 border z-40  bottom-[20px] rounded-lg border-none  bg-gradient-to-r hover:from-[#2B1489] hover:to-[#104DBC] transition-all duration-500 from-[#104DBC] to-[#2B1489]'>
                                <a href=''>
                                    <GrFacebookOption className='text-white text-xl not-italic font-themif' />
                                </a>
                                <a href=''>
                                    <FaTwitter className='text-white text-xl not-italic font-themif' />
                                </a>
                                <a href=''>
                                    <FaPinterestP className='text-white text-xl not-italic font-themif' />
                                </a>
                            </motion.div> : null} */}
