import React from 'react';

function ClientCard(props) {
  return (
    <div className="col-span-1">
      <div className="flex flex-col gap-3 border-2 w-full  shadow-xl col-span-1 overflow-hidden rounded-lg h-[370px]">
        <div className='flex flex-col items-center justify-center overflow-hidden h-[80%]'>
          <img className="w-full h-full object-contain hover:scale-75 cursor-pointer transition-all hover:duration-1000" src={props.img} />
        </div>
        <div className='lg:py-5 md:py-3 py-2 px-3 h-[20%]'>
          <p className='py-2 font-semibold  font-spartan text-xl text-center'>{props.title}</p>
        </div>
      </div>
    </div>
  )
}

export { ClientCard };
