import React, { useContext } from 'react'
import { BottomContent } from '../../App';

function PrivacyPolicy() {
  const UseBottomContext = useContext(BottomContent);

  return (
    <div ref={UseBottomContext} className='py-10'>
      <div className='max-w-[1200px] mx-auto p-3 flex flex-col gap-3'>
        <div className='text-3xl self-center capitalize font-semibold text-black'>Our Commitment to Privacy</div>
        <p>Welcome to Illusion Spot Line website (the ”Site”). For the purposes of this Policy, the terms “we”, “us”, “our”, “Company” and, “Company” refer to Illusion Spot Line, and all of our affiliates. ”You” refers to you, as a user of this site. Explained in this section is our policy regarding any personal information you might supply to us when you visit our web site(s). Our Privacy Policy (the ”Policy”) was developed as an extension of our commitment to combine the highest-quality products and services with the highest level of integrity in dealing with our customers and partners. This Policy is designed to assist you in understanding how we collect, use and safeguard the personal information you provide to us and to assist you in making informed decisions when using our Site and our products and services. This statement will be continuously assessed against new technologies, business practices and our customers’ needs.</p>
        <p>Your Consent.</p>
        <p>By using this Site, you consent to our collection and use of your personal information as described in this Privacy Policy. Company’s Privacy Policy applies to individuals and we reserve the right to change this Privacy Policy, along with related procedures, at any time.</p>
        <p>Collection, Use and Retention of Client Information.</p>
        <p>When you visit our Site you may provide us with two types of information:
          1) personal information you knowingly choose to disclose that is collected on an individual basis; and
          2) web site use information collected on an aggregate basis as you and others browse our Site.
        </p>
        <p>Security of information :</p>
        <p>We will ensure security of your information by maintaining them in very secure environments which are protected by robust security procedures.</p>
        <div className='text-lg font-semibold text-[#104cba]'>1. Personal Information — Our Use of Information That You Provide Us.</div>
        <p>A. General Policy</p>
        <p>Broadly speaking, we use personal information for purposes of administering our business activities, providing customer service and making available other products and services to our customers and prospective customers. Occasionally, we may also use the information we collect to notify you about important changes to our Site, new services and special offers we think you will find valuable. The lists used to send you product and service offers are developed and managed under our traditional corporate standards designed to safeguard the security and privacy of our customers’ personal information. If you desire not to receive these offers, please send a mail to info@Illusion Spot Line.com.</p>
        <p>B. Credit Card Information</p>
        <p>If you choose to purchase products or services from Company or our partners, you may need to give personal information and authorization to obtain information from various credit services. For example, you may need to provide the following information:</p>
        <p>Name
          Mailing address
          E-mail address
          Credit card number
          Home and/or business phone number Other personal information
          E-mail Information
        </p>
        <p>C. E-Mail.</p>
        <p>If you choose to correspond with us through e-mail, we may retain the content of your e-mail and our response so that we can more efficiently handle any follow-up questions you may have.</p>
        <p>D. Online Applications.</p>
        <p>When you apply on the Site for accounts or services, you provide personal information that is necessary for us to process your application. Company, along with our affiliates, may keep the information you provide to us, along with information we collect from outside sources, so that we can offer you accounts and services related to your financial needs.</p>
        <p>E. Online Surveys.</p>
        <p>
          The information you provide to Company via online surveys and promotions on the Site will be used for internal marketing purposes, including for the development of new services and information on the Site.
        </p>
        <div className='text-lg font-semibold text-[#104cba]'>2. Web Site Use Information.</div>
        <p>Similar to other commercial web sites, our Site utilizes a standard technology called “cookies” and web server logs to collect information about how our Site is used.</p>
        <p>A. What Are Cookies?</p>
        <p>”Cookies” are a feature of web browser software that allows web servers to recognize the computer used to access a web site. They are small pieces of data that are stored by a user’s web browser on the user’s hard drive. Information gathered through cookies and web server logs may include information such as the date and time of visits, the pages viewed, time spent at the Site, and the web sites visited just before and just after our Site. Cookies can remember what information a user accesses on one web page to simplify subsequent interactions with that web site by the same user or to use the information to streamline the user’s transactions on related web pages. This makes it easier for a user to move from web page to web page and to complete commercial transactions over the Internet. Cookies should make your online experience easier and more personalized.</p>
        <p>B. How We Use Information We Collect from Cookies.</p>
        <p>We use web site browser software tools such as cookies and web server logs to gather information about our Site users’ browsing activities in order to constantly improve our Site and better serve our customers. This information assists us to design and arrange our web pages in the most user-friendly manner and to continually improve our Site to better meet the needs of our customers and prospective customers. We do not use these technologies to capture your individual e-mail address or any personally identifying information about you although they do permit us to send focused online banner advertisements or other such responses to you.</p>
        <div className='text-lg font-semibold text-[#104cba]'>3. Disclosure of Information.</div>
        <p>A. Generally</p>
        <p>We may provide aggregate information about our customers, sales, Site traffic patterns and related Site information to our affiliates or reputable third parties, but this information will not include personally identifying data, except as otherwise provided in this Privacy Policy.</p>
        <p>B. Legally Compelled Disclosure of Information.</p>
        <p>We may disclose information when legally compelled to do so, in other words, when we, in good faith, believe that the law requires it or for the protection of our legal rights.</p>
        <p>C. Sharing Information with Affiliates.</p>
        <p>From time to time, you may notice offers from outside companies advertised on our Site. We take measures to select product or service providers that are responsible and afford privacy protections to their customers. However, we cannot and do not make any representations about the practices and policies of these companies.</p>
        <p>We partner with Microsoft Clarity and Microsoft Advertising to capture how you use and interact with our website through behavioral metrics, heatmaps, and session replay to improve and market our products/services. Website usage data is captured using first and third-party cookies and other tracking technologies to determine the popularity of products/services and online activity. Additionally, we use this information for site optimization, fraud/security purposes, and advertising.</p>
        <p>D. Sharing Information with Strategic Partners.</p><p>We may enter into strategic marketing alliances or partnerships with third parties who may be given access to personal information including your name, address, telephone number and e-mail for the purpose of providing you information regarding products and services that we think will be of interest to you. In connection with strategic marketing alliances or partnerships, we will retain all ownership rights to the information, and we will not share information regarding your personal financial data. </p>
        <div className='text-lg font-semibold text-[#104cba]'>4. Employee Access, Training and Expectations.</div>
        <p>Our corporate values, ethical standards, policies and practices are committed to the protection of customer information. In general, our business practices limit employee access to confidential information, and limit the use and disclosure of such information to authorized persons, processes and transactions.</p>
        <div className='text-lg font-semibold text-[#104cba]'>5. Other Web Sites Linked to Our Site.</div>
        <p>We are not responsible for the practices employed by web sites linked to or from our Site nor the information or content contained therein. Often links to other web sites are provided solely as pointers to information on topics that may be useful to the users of our Site.</p>
        <p>Please remember that when you use a link to go from our Site to another web site, our Privacy Policy is no longer in effect. Your browsing and interaction on any other web site, including web sites which have a link to our Site, is subject to that web site’s own rules and policies. Please read over those rules and policies before proceeding.</p>
        <div className='text-lg font-semibold text-[#104cba]'>6. Children’s Privacy.</div>
        <p>Our Site is not intended for the use of children. We do not knowingly market to, or solicit data from, children.</p>
        <p>
          For further information on terms governing use of this Site, see COMPANY’s Terms of Use Agreement.
        </p>
        <div className='text-lg font-semibold text-[#104cba]'>Refund Policy

        </div>
        <p>There is no refund avialable based on the privacy policy. For more details contact us at info@illusionspotline.com through email or call us on +91 79047 04039 /

          +91 99522 98666.
        </p>
      </div>
    </div>
  )
}

export default PrivacyPolicy