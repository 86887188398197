import React, { useContext } from 'react';
import { motion } from "framer-motion";
import fadeIn from '../variants';
import Shape01 from '../Assert/Year/Shape01.webp';
import { ProcessData } from '../utils/ProcessData';
import GetInTouch from '../Assert/vec03-7.png';
import Home5 from '../Assert/Home5-17-7.png';
import Home7 from '../Assert/Home5-15-7.png';
import { BrandingData, ServiceData } from '../utils/BrandingData';
import Button from '../Constant/Button';
import FeaturedServicesCard from '../Constant/FeaturedServicesCard';
import Banner from '../Assert/Ban.png';
import LightDarkBanner from '../Assert/Service/Img_13.webp';
import { Helmet } from 'react-helmet';
import { BottomContent } from '../App';


function Service() {
  const UseBottomContext = useContext(BottomContent);

  return (
    <div ref={UseBottomContext} className='flex flex-col  '>

      <div className='flex flex-col py-10 gap-5'>
        {/* <Helmet>
        <title>Service</title>
      </Helmet> */}
        <div style={{ backgroundImage: `url(${Shape01})` }} className='bg-no-repeat bg-contain  bg-left  overflow-hidden'>
          <div className='max-w-[1200px] mx-auto p-3'>
            {BrandingData.map((item, index) =>
              <div key={index} className='flex flex-row lg:flex-nowrap flex-wrap items-center justify-between w-full gap-5'>
                {item.img != null ?
                  <div className='lg:w-[48%] w-full flex flex-col lg:items-start items-center'>
                    <img src={item.img} />
                  </div> : null}
                <div className='flex flex-col space-y-5 lg:w-[50%] w-full'>
                  <motion.div variants={fadeIn("bottom", 0.3)} initial="hidden" whileInView={"visible"}  className='text-lg font-semibold text-[#104cba]'>{item.company}</motion.div>
                  <motion.div variants={fadeIn("bottom", 0.4)} initial="hidden" whileInView={"visible"}  className='text-3xl capitalize font-semibold text-black'>{item.title}</motion.div>
                  <motion.p variants={fadeIn("bottom", 0.6)} initial="hidden" whileInView={"visible"}  className='text-base text-[#828282]'>{item.description}</motion.p>
                  <div className='grid lg:grid-cols-2 grid-cols-1 gap-5'>
                    {item.content.map((item, index) =>
                      <motion.div variants={item.variants} initial="hidden" whileInView={"visible"}  key={index} className='col-span-1 lg:h-[110px] h-[150px] p-4 showdaColor rounded bg-white flex flex-row items-center space-x-5'>
                        <img className='w-[28px] h-[28px] object-contain hover:-translate-x-4 transition-all duration-150' src={item.img1} />
                        <div className='flex flex-col space-y-2'>
                          <div className='text-lg text-black font-semibold'>
                            {item.header}
                          </div>
                          <div className='text-base text-[#828282]'>
                            {item.headerDescription}
                          </div>
                        </div>
                      </motion.div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='py-20 overflow-hidden'>
          <div className='max-w-[1200px] mx-auto px-3'>
            {ProcessData.map((item, index) =>
              <div key={index} className='flex flex-row lg:flex-nowrap flex-wrap items-center justify-between w-full gap-5'>
                <div className='flex flex-col space-y-5 lg:w-[44%] w-full'>
                  <motion.div variants={fadeIn("bottom", 0.2)} initial="hidden" whileInView={"visible"}  className='text-lg font-semibold text-[#104cba]'>{item.company}</motion.div>
                  <motion.div variants={fadeIn("bottom", 0.4)} initial="hidden" whileInView={"visible"}  className='text-3xl capitalize font-semibold text-black'>{item.title}</motion.div>
                  <motion.p variants={fadeIn("bottom", 0.5)} initial="hidden" whileInView={"visible"}  className='text-base text-[#828282]'>{item.description}</motion.p>
                  <motion.div variants={fadeIn("bottom", 0.6)} initial="hidden" whileInView={"visible"}  className='flex flex-col space-y-4'>
                    {item.content.map((process, index) =>
                      <div key={index} className='flex flex-col space-y-2'>
                        <div className='flex flex-row space-x-2 items-center justify-between text-base font-medium text-[#828282]'>
                          <span>{process.name}</span>
                          <span>{process.count}</span>
                        </div>
                        <div className='w-full h-3 shadow-lg rounded-full border bg-white'>
                          <div className={`w-[${process.count}] bg-gradient-to-r border-[#ff3834]  from-[#ff3834] to-[#ff7133] h-full rounded-full`} />
                        </div>
                      </div>
                    )}
                  </motion.div>
                </div>
                <div className='lg:w-[48%] w-full'>
                  <motion.img variants={fadeIn("right", 0.6)} initial="hidden" whileInView={"visible"}  src={item.img} />
                </div>
              </div>
            )}
          </div>
        </div>
        <div style={{ backgroundImage: `url(${LightDarkBanner})` }} className='bg-no-repeat bg-cover  bg  flex flex-col overflow-hidde'>
          <div className='max-w-[1200px] mx-auto p-3'>
            <div className='flex flex-col space-y-10'>
              <div className='flex flex-col items-center justify-center space-y-5'>
                <motion.div variants={fadeIn("bottom", 0.2)} initial="hidden" whileInView={"visible"}  className='text-lg font-semibold text-[#104cba]'>Featured Services</motion.div>
                <motion.div variants={fadeIn("bottom", 0.4)} initial="hidden" whileInView={"visible"}  className='text-3xl capitalize text-center font-semibold text-black'>Engaging Creative minds via technology
                </motion.div>
              </div>
              <FeaturedServicesCard />
              <img className='w-6 h-6 object-contain left-[63%] absolute bottom-[20px]' src={Banner} />
            </div>
          </div>

          <div className='max-w-[1200px] mx-auto p-3 relative flex flex-col gap-10 py-20'>
            <div className='flex flex-row lg:flex-nowrap flex-wrap  justify-between w-full gap-5'>
              <div className='lg:w-[46%] w-full'>
                <motion.img variants={fadeIn("left", 0.2)} initial="hidden" whileInView={"visible"}  src={GetInTouch} />
              </div>
              <img className='absolute left-[46%]' src={Home5} />
              <div className='flex flex-col lg:w-[48%] w-full space-y-5'>
                <motion.div variants={fadeIn("bottom", 0.3)} initial="hidden" whileInView={"visible"}  className='text-lg font-semibold text-[#104cba]'>Get in Touch              </motion.div>
                <motion.div variants={fadeIn("bottom", 0.5)} initial="hidden" whileInView={"visible"}  className='text-4xl capitalize font-semibold text-black'>we provide best services.nNeed Help? </motion.div>
                <motion.div variants={fadeIn("bottom", 0.8)} initial="hidden" whileInView={"visible"}  className='flex flex-col space-y-4'>
                  <input placeholder='Your Name*' className='h-[55px] bg-[#fafafa] mb-2 text-[#696969] border-[1px] outline-none border-[#d0dcec] rounded px-5 hover:border-[#6f39c2] transition-all duration-150' />
                  <input placeholder='Your Email*' className='h-[55px] bg-[#fafafa] mb-2 text-[#696969] border-[1px] outline-none border-[#d0dcec] rounded px-5 hover:border-[#6f39c2] transition-all duration-150' />
                  <input placeholder='Messages*' className='h-[120px] bg-[#fafafa] mb-2 text-[#696969] border-[1px] outline-none border-[#d0dcec] rounded px-5 hover:border-[#6f39c2] transition-all duration-150' />
                  <Button name="Send Message" />
                </motion.div>
              </div>
            </div>
          </div>
        </div>

        <div className='max-w-[1200px] mx-auto p-3 w-full'>
          <div className='flex flex-row gap-5'>
            <div className='lg:w-[46%] w-full'>
            </div>
            <div className='lg:w-[46%] w-full flex flex-col items-center justify-center'>
              <img src={Home7} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Service;