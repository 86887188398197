import AboutImage from '../Assert/05-8.png';
import { PiMapPin } from "react-icons/pi";
import { GiSmartphone } from "react-icons/gi";
import { TfiEmail } from "react-icons/tfi";
import fadeIn from '../variants';

export const ContactData = [
    {
        img: AboutImage,
        company: "Get in Touch",
        title: "we provide best services <br/> Need Help?",
       
    },
]