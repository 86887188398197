import Planext4u from "../Assert/OurProject/p4u.jpg"
import VocaBuilder from "../Assert/OurProject/voca.jpg"
import School from "../Assert/OurProject/school.jpg"
import Notebook from "../Assert/OurProject/notebook.jpg"
import BigBiking from "../Assert/OurProject/big.jpg"
import IntelliGame from "../Assert/OurProject/kgprep.jpg"
import KGPlus from "../Assert/OurProject/kgplus.jpg"
import KGPrep from "../Assert/OurProject/kgprep1.jpg"
export const OurProjectData = [
    {
        title: "Planext4u",
        img: Planext4u,
        link: "hkk",
    },
    {
        title: "VocaBuilder",
        img: VocaBuilder,
        link: "bbc",
    },
    {
        title: "School Management",
        img: School,
        link: "bbc",
    },
    {
        title: "Notebook Elite",
        img: Notebook,
        link: "bbc",
    },
    {
        title: "Big Biking commune",
        img: BigBiking,
        link: "bbc",
    },
    // {
    //   title: "Rite Angle",
    //   img: Rite,
    //   link: "bbc",
    // },
    {
        title: "IntelliGame",
        img: IntelliGame,
        link: "bbc",
    },
    {
        title: "KG Plus",
        img: KGPlus,
        link: "bbc",
    },
    {
        title: "KG Prep",
        img: KGPrep,
        link: "bbc",
    },
];