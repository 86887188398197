import React from 'react';
import { motion } from "framer-motion";
import fadeIn from '../variants';

function BoxCard(props) {
    return (
        <section className='flex flex-row lg:flex-nowrap flex-wrap items-center justify-between'>
            {props.img != null ?
                <div className='lg:w-[48%] w-full'>
                    <motion.img variants={fadeIn("left", 0.2)} initial="hidden" whileInView={"visible"}  src={props.img} />
                    {/* <img className='img1' src={props.img} /> */}
                </div> : null}
            <div className='flex flex-col space-y-5 lg:w-[48%] w-full'>
                <motion.div variants={fadeIn("bottom", 0.2)} initial="hidden" whileInView={"visible"}  className='text-lg font-semibold text-[#104cba]'>{props.company}</motion.div>
                <motion.div variants={fadeIn("bottom", 0.4)} initial="hidden" whileInView={"visible"}  className='text-3xl capitalize font-semibold text-black'>{props.title}</motion.div>
                <motion.p variants={fadeIn("bottom", 0.6)} initial="hidden" whileInView={"visible"}  className='text-base text-[#828282]'>{props.description}</motion.p>
                {props.descri.map((item, index) =>
                    <motion.div variants={fadeIn("left", 0.4)} initial="hidden" whileInView={"visible"}  key={index} className='flex flex-row items-start space-x-5'>
                        <img className='w-[28px] h-[28px] object-contain hover:-translate-x-4 transition-all duration-150' src={item.img1} />
                        <div className='flex flex-col space-y-2'>
                            <div className='text-lg text-black font-semibold'>
                                {item.header}
                            </div>
                            <div className='text-base text-[#828282]'>
                                {item.headerDescription}
                            </div>
                        </div>
                    </motion.div>
                )}
            </div>

        </section>
    )
}

export default BoxCard