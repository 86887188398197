import AboutImage from '../Assert/Vector01-7.webp';
import tickIcon from '../Assert/tick-7.png';

export const AboutCompany= [
    {
        img: AboutImage,
        company: "About Company",
        title: "Illusion Spot Line",
        description1: "Since 2011, Illusion Spot Line has been the ‘go-to’ Custom Graphics Studio, trusted by some of the world’s top brands. Illusions Spot line delivers on Time and on Budget. We are Responsive, Friendly, and go the extra mile. We are here for all you needs.",
        description2: "We have several years’ experience working with Web Design, Mobile Application, 3D & 2D Game Development, Architects visualization & Product visualization that make us supreme in every field. With skilled and experienced team we always complete our commitment with client’s satisfaction.",
        description3: "Digitization is transforming every business into a technology business, with boundless opportunities all along the value chain. To benefit from these opportunities, you have to move fast or risk losing the first-mover advantage. From our headquarters in Minneapolis, we help companies tap into the technology expertise and operational efficiencies made possible by our global- delivery model—and always go for the gold.",
        
    },
]