import ServiceImage from '../Assert/vec02-1-7.png';

export const ProcessData = [
    {
        img: ServiceImage,
        company: "Our Process",
        title: "Generating New business growth plans",
        description: "Nulla eleifend, lectus eu gravida facilisis, ipsum metus faucibus eros, vitae vulputate nibh libero ac metus phasellus magna erat",
        content: [
            {
                name: "Business Goal",
                count: "80%",
            },
            {
                name: "Traffic Growth",
                count: "90%",
            },
            {
                name: "Competitor Research",
                count: "80%",
            },

        ]
    },
]