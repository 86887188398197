export const HeaderMeneu = [
    {
        name: "Home",
        route: "/",
    },
    {
        name: "About",
        route: "/About",
    },
    {
        name: "Services",
        // route: "/Services",
        subName:[
            {
                name:"Branding",
                route:"/Branding"
            },
            {
                name:"Web Designing",
                route:"/Web_Designing"
            },
            {
                name:"Mobile Applications",
                route:"/mobile_apps"
            },
            {
                name:"Game Development",
                route:"/Game_Development"
            }
        ]
    },
    // {
    //     name: "Products",
    //     route: "/Products",
    // },
    {
        name: "Clients",
        route: "/Clients",
    },
]