import { LuFileBarChart2 } from 'react-icons/lu';
import WebDesignImage from '../Assert/Service/Ser05-7.webp';
import fadeIn from '../variants';
import { RxGear } from 'react-icons/rx';
import { BsBarChart } from 'react-icons/bs';

export const WebDesignData= [
    {
        img: WebDesignImage,
        company: "PLANNING",
        title: "Web Designing",
        description1: "We specialize in all aspects of Web design and development, from eCommerce solutions and Web applications to robust online marketing (SEO/SEM) campaigns.we know how to build dynamic Web solutions that will help your company reach its potential.",
        description2: "We believe in developing long-term partnerships that empower our clients and expand their scope – regardless of size or industry – to become even more successful than they already are.",
        description3: "Our expert designers, developers and project managers will guide you through the process of customizing an online user experience that meets your unique business needs and scales your company.",
        
    },
]

export const WebDesigningData = [
    {
        img: <BsBarChart />,
        title: "E-Commerce Solutions",
        description: "A one-stop shop for your e-commerce solutions, we will enable your business sell products and services online, conveniently and reliably.",
        // variants: fadeIn("left", 0.3)
    },
    {
        img: <LuFileBarChart2 />,
        title: "Content Management System",
        description: "If you need a content management solution that can be the core for your digital brand, then we can be of help!",
        // variants: fadeIn("right", 0.4)
    },
    {
        img: <RxGear />,
        title: "Web Portal Development",
        description: "Be it small, medium, or large or even a B2B or a paid service portal, we conceive ideas and define businesses with excellence!",
        // variants: fadeIn("left", 0.5)
    },
    {
        img: <BsBarChart />,
        title: "Responsive Web Design",
        description: "Meet all your responsive we design needs with us and improve your website viewing experience!",
        // variants: fadeIn("right", 0.3)
    },
    {
        img: <BsBarChart />,
        title: "Website Redesigning",
        description: "Is your old website a stumbling block to your business? Do not worry! We help you redesign your website with an eye so that it drives huge web traffic unto you!",
        // variants: fadeIn("left", 0.3)
    },
    {
        img: <LuFileBarChart2 />,
        title: "Website Maintenance",
        description: "Your website is your first job! When it comes to updating your website we take it up! We make sure that your website stays up-to-date to receive more traffic Mobile application development",
        // variants: fadeIn("right", 0.4)
    },
    {
        img: <RxGear />,
        title: "Search Engine Optimization",
        description: "Rank high and earn site traffic easily with our unique Search Engine Optimization techniques!",
        // variants: fadeIn("left", 0.5)
    },
    {
        img: <BsBarChart />,
        title: "Search Engine Marketing",
        description: "At Mirror Minds we would do everything to offer you a successful online presence with our proven marketing strategies!",
        // variants: fadeIn("right", 0.3)
    },
    {
        img: <BsBarChart />,
        title: "Social Media Optimization",
        description: "Streamline your business search marketing efforts easily with our services that will help you show you where and what people are talking about you, where and how to get involved, and the tactics you should use to help increase brand awareness",
        // variants: fadeIn("left", 0.3)
    },
    {
        img: <LuFileBarChart2 />,
        title: "Inbound Marketing",
        description: "While a good-to-read and user-friendly website is one thing, generating leads is another. With our Inbound Marketing we take you from where you are to where you want to be",
        // variants: fadeIn("right", 0.4)
    },
    {
        img: <RxGear />,
        title: "Email Marketing",
        description: "Reach and engage with your customers with no glitch using our smart Email Marketing solution which will make heads turn at your emails!",
        // variants: fadeIn("left", 0.5)
    },
    {
        img: <BsBarChart />,
        title: "Online Advertising",
        description: "Are you someone who is aware that without promotion, something terrible might happen to your business, then you should seek our help to online advertise with us!",
        // variants: fadeIn("right", 0.3)
    },
]