import React, { useContext, useState } from 'react';
import BoxCard from '../Constant/BoxCard';
import { AboutCompany } from '../utils/AboutCompany';
import { motion } from "framer-motion";
import fadeIn from '../variants';
import BannersComponent from '../Constant/BannersComponent';
import { ProcessData } from '../utils/ProcessData';
import Banner11 from "../Assert/Choose/07-7.png";
import CircleImage from "../Assert/Choose/Ban13-7.png";
import TickIcon from "../Assert/Choose/Capa-7.png";
import MapImage from "../Assert/Choose/map1.webp";
import Test1 from "../Assert/Choose/testi-lady-7.webp";
import Test2 from "../Assert/Choose/test-1-7.png";
import Test3 from "../Assert/Choose/testimonials-7.png";
import RoundIcon from "../Assert/Choose/P-06-7.png";
import QuoteIcon from "../Assert/Choose/Quote.png";
import Phote1 from "../Assert/Choose/Service/11-7.png";
import Phote2 from "../Assert/Choose/Service/12-7.png";
import Phote3 from "../Assert/Choose/Service/13-7.png";
import Phote4 from "../Assert/Choose/Service/14-7.png";
import Phote5 from "../Assert/Choose/Service/15-7.png";
import Phote6 from "../Assert/Choose/Service/16-7.png";
import Work1 from "../Assert/Choose/01-1-7.png";
import Work2 from "../Assert/Choose/02-1-7.png";
import Work3 from "../Assert/Choose/03-1-7.png";
import Work4 from "../Assert/Choose/04-1-7.png";
import Work5 from "../Assert/Choose/05-1-7.png";
import { Helmet } from 'react-helmet';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BottomContent } from '../App';
function Products() {
  const ProminentITServicesData = [
    {
      img: TickIcon,
      header: "Business Collaboration",
      headerDescription: "Morbi sagittis hendrerit nulla in ultricies. Cras n en diam elementum pretium hendrerit ultricies",
      variants: fadeIn("top", 0.3)
    },
    {
      img: TickIcon,
      header: "Engineering & Services",
      headerDescription: "Proin scelerisque porttitor feugiat. Mauris meopo en varius tellus elusei suscipit hendrerit",
      variants: fadeIn("top", 0.3)
    },
    {
      img: TickIcon,
      header: "Creative Minds",
      headerDescription: "Aenean pharetra est dictum, ultricies nontem er sum. Cras en diam pretium hendrerit ultricies",
      variants: fadeIn("bottom", 0.3)
    },
    {
      img: TickIcon,
      header: "Revenue Generation",
      headerDescription: "Mauris varius tellus eu lectus suscipit, sed sus cipito nisi sagai aenean pharetra ultricies",
      variants: fadeIn("bottom", 0.3)
    },
  ]
  const ServiceTestimonials = [

    {
      img: Test1,
      name: "Anie Gomez",
      role: "Web Developer",
      headerDescription: "Praesent eget metus pulvinar, eleifend dolor sed, tempus nibh. Lorem ipsum dolor sit amet Fusce vel scelerisque erat facilisis. Donec hendrerit diam sapien, vel scelerisque erat facilisis ut. Fusce sit amet leo ac eros dignissim porta in vel metus.",
    },
    {
      img: Test2,
      name: "Julia Miller",
      role: "Web Designer",
      headerDescription: "Morbi molestie blandit libero et tempus.Praesent eget metus pulvinar, eleifend dolor sed, tempus nibh. Lorem ipsum dolor sit amet Fusce vel scelerisque erat facilisis. Donec hendrerit diam sapien, vel scelerisque erat facilisis lorem ipsum dolor sit amet Fusce.",
    },
    {
      img: Test3,
      name: "Anie Gomez",
      role: "Anie Gomez",
      headerDescription: "Fusce at nisi sed sapien volutpat malesuada. All the Lorem Ipsum genchunks as dolor sit amet consectetur lorem ipsum dolor sit amet Fusce at nisi sed sapien volutpat malesuada. Lorem ipsum dolor sit amet Fusce vel scelerisque erat facilisis.",
    },
  ]

  const settings = {
    dots: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    // autoplay: true,
    autoplaySpeed: 1000,
  };
  const UseBottomContext=useContext(BottomContent);

  return (
    <div ref={UseBottomContext} className='flex flex-col  '>
      {/* <Helmet>
        <title>Choose</title>
      </Helmet> */}
      <BannersComponent header="Why Choose Us" title="Why Choose Us" />
      <div className='py-20 bg-white overflow-hidden'>
        <div className='max-w-[1200px] mx-auto p-3 h-full'>
          <div className='flex flex-col items-center justify-center space-y-5'>
            <motion.div variants={fadeIn("bottom", 0.2)} initial="hidden" whileInView={"visible"}  className='text-lg font-semibold text-[#104cba]'>How We Work
            </motion.div>
            <motion.div variants={fadeIn("bottom", 0.4)} initial="hidden" whileInView={"visible"}  className='text-3xl self-center capitalize font-semibold text-black'>Preparing for your success
            </motion.div>
            <motion.p variants={fadeIn("bottom", 0.6)} initial="hidden" whileInView={"visible"}  className='self-center text-center lg:w-[60%]'>Maecenas varius sem dui. Vestibulum semper ante a viverra posuere. Maecenas eleifend fermentum eros, et tristique eros.
            </motion.p>
          </div>
          <div className='mt-10 st h-full'>
            <div className='flex flex-row md:flex-nowrap flex-wrap items-center justify-between w-full h-full'>
              <div className='md:w-[47%] w-full flex flex-col items-center justify-center gap-10'>
                <motion.img variants={fadeIn("left", 0.3)} initial="hidden" whileInView={"visible"}  className='w-[230px] h-[205px] object-contain' src={Work1} />
                <motion.div variants={fadeIn("left", 0.4)} initial="hidden" whileInView={"visible"}  className='item-data'>
                  <div className='flex flex-row items-center space-x-5'>
                    <div className='RoundBox from-[#ff3834] to-[#ff7133]'>02</div>
                    <div className='flex flex-col space-y-2'>
                      <div className='text-xl text-black font-semibold capitalize'>
                        Management System
                      </div>
                      <p>
                        Generators on the Internet tend to repeat predefined chunks as necessary
                      </p>
                    </div>
                  </div>
                </motion.div>
                <motion.img variants={fadeIn("left", 0.3)} initial="hidden" whileInView={"visible"}  className='w-[230px] h-[205px] object-contain' src={Work3} />
                <motion.div variants={fadeIn("left", 0.4)} initial="hidden" whileInView={"visible"}  className='item-data'>
                  <div className='flex flex-row items-center space-x-5'>
                    <div className='RoundBox'>04</div>
                    <div className='flex flex-col space-y-2'>
                      <div className='text-xl text-black font-semibold capitalize'>
                        accurate testing Process
                      </div>
                      <p>
                        Generators on the Internet tend to repeat predefined chunks as necessary
                      </p>
                    </div>
                  </div>
                </motion.div>
                <motion.img variants={fadeIn("left", 0.3)} initial="hidden" whileInView={"visible"}  className='w-[230px] h-[205px] object-contain' src={Work5} />
              </div>
              <div className='md:w-[5%] w-full h-full flex flex-col bg-red-500'>
                {/* <div className='border-2 h-full'></div> */}
              </div>
              <div className='md:w-[47%] w-full flex flex-col items-center justify-center gap-10'>
                <motion.div variants={fadeIn("right", 0.4)} initial="hidden" whileInView={"visible"}  className='item-data'>
                  <div className='flex flex-row items-center space-x-5'>
                    <div className='RoundBox'>01</div>
                    <div className='flex flex-col space-y-2'>
                      <div className='text-xl text-black font-semibold capitalize'>
                        Business Collaboration

                      </div>
                      <p>
                        Generators on the Internet tend to repeat predefined chunks as necessary
                      </p>
                    </div>
                  </div>
                </motion.div>
                <motion.img variants={fadeIn("right", 0.3)} initial="hidden" whileInView={"visible"}  className='w-[230px] h-[205px] object-contain' src={Work1} />
                <motion.div variants={fadeIn("right", 0.4)} initial="hidden" whileInView={"visible"}  className='item-data'>
                  <div className='flex flex-row items-center space-x-5'>
                    <div className='RoundBox from-[#4392d5] to-[#4faede]'>03</div>
                    <div className='flex flex-col space-y-2'>
                      <div className='text-xl text-black font-semibold capitalize'>
                        Making Idea

                      </div>
                      <p>
                        Generators on the Internet tend to repeat predefined chunks as necessary
                      </p>
                    </div>
                  </div>
                </motion.div>
                <motion.img variants={fadeIn("right", 0.3)} initial="hidden" whileInView={"visible"}  className='w-[230px] h-[205px] object-contain' src={Work5} />
                <motion.div variants={fadeIn("right", 0.4)} initial="hidden" whileInView={"visible"}  className='item-data'>
                  <div className='flex flex-row items-center space-x-5'>
                    <div className='RoundBox from-[#ff3834] to-[#ff7133]'>05</div>
                    <div className='flex flex-col space-y-2'>
                      <div className='text-xl text-black font-semibold capitalize'>
                        our successful project

                      </div>
                      <p>
                        Generators on the Internet tend to repeat predefined chunks as necessary
                      </p>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='py-24  bg-gradient-to-r border-[#0f51bf] from-[#30077d] to-[#0f51bf] overflow-hidden'>
        <div className='max-w-[1200px] mx-auto p-3'>
          <section className='flex flex-col space-y-10'>
            <div className='flex flex-col items-center justify-center space-y-5'>
              <motion.div variants={fadeIn("bottom", 0.2)} initial="hidden" whileInView={"visible"}  className='text-lg font-semibold text-white'>HWhat We Offer
              </motion.div>
              <motion.div variants={fadeIn("bottom", 0.4)} initial="hidden" whileInView={"visible"}  className='text-4xl self-center capitalize text-center font-semibold text-white'>Prominent IT Services </motion.div>
            </div>
            <div className='grid lg:grid-cols-2 grid-cols-1 gap-10 h-full'>
              {ProminentITServicesData.map((item, index) =>
                <motion.div variants={item.variants} initial="hidden" whileInView={"visible"}  key={index} className='flex flex-row  items-center gap-10 p-8 hover:bg-[#103AB1] rounded-md transition-all duration-200'>
                  <img className='object-contain' src={item.img} />
                  <div className='flex flex-col space-y-3'>
                    <div className='text-white capitalize font-semibold text-lg not-italic'>{item.header}</div>
                    <p className='text-white'>{item.headerDescription}</p>
                  </div>
                </motion.div>
              )}
            </div>
          </section>
        </div>
      </div>
      <div className='bg-[#F6F6FF] py-20 overflow-hidden'>
        <div className='max-w-[1200px] mx-auto px-3'>
          <div className='flex flex-row lg:flex-nowrap flex-wrap items-center justify-between w-full gap-5'>
            {ProcessData.map((item, index) =>
              <div ey={index} className='flex flex-col space-y-5 lg:w-[44%] w-full'>
                <motion.div variants={fadeIn("bottom", 0.2)} initial="hidden" whileInView={"visible"}  className='text-lg font-semibold text-[#104cba]'>{item.company}</motion.div>
                <motion.div variants={fadeIn("bottom", 0.4)} initial="hidden" whileInView={"visible"}  className='text-3xl capitalize font-semibold text-black'>{item.title}</motion.div>
                <motion.p variants={fadeIn("bottom", 0.5)} initial="hidden" whileInView={"visible"} >{item.description}</motion.p>
                <motion.div variants={fadeIn("bottom", 0.6)} initial="hidden" whileInView={"visible"}  className='flex flex-col space-y-4'>
                  {item.content.map((process, index1) =>
                    <div key={index1} className='flex flex-col space-y-2'>
                      <div className='flex flex-row space-x-2 items-center justify-between text-base font-medium text-[#828282]'>
                        <span>{process.name}</span>
                        <span>{process.count}</span>
                      </div>
                      <div className='w-full h-3 shadow-lg rounded-full border bg-white'>
                        <div className={`w-[${process.count}] bg-gradient-to-r border-[#ff3834] from-[#ff3834] to-[#ff7133] h-full rounded-full`} />
                      </div>
                    </div>
                  )}
                </motion.div>
              </div>)}
            <div className='lg:w-[48%] w-full relative'>
              <img className='object-contain absolute left-[20px] -bottom-[70px] z-20' src={CircleImage} />
              <motion.img variants={fadeIn("right", 0.5)} initial="hidden" whileInView={"visible"}  src={Banner11} />
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundImage: `url(${MapImage})` }} className='bg-no-repeat bg-cover py-24 bg-fixed overflow-hidden'>
        <div className='max-w-[1200px] mx-auto p-3'>
          <section className='flex flex-col'>
            <div className='flex flex-col items-center justify-center space-y-5'>
              <motion.div variants={fadeIn("bottom", 0.2)} initial="hidden" whileInView={"visible"}  className='text-lg font-semibold text-[#104cba]'>Service Testimonials
              </motion.div>
              <motion.div variants={fadeIn("bottom", 0.4)} initial="hidden" whileInView={"visible"}  className='text-3xl capitalize font-semibold text-black'>What people say about us
              </motion.div>
              <motion.p variants={fadeIn("bottom", 0.5)} initial="hidden" whileInView={"visible"}  className='text-center'>Maecenas varius sem dui. Vestibulum semper ante a viverra posuere.<br /> Maecenas eleifend fermentum eros, et tristique eros. </motion.p>
            </div>
            <div className='flex items-center justify-between lg:flex-nowrap flex-wrap h-full'>
              {/* <div className=' flex-col space-y-5 w-[15%] justify-between relative h-full lg:flex hidden'>
                <img src={Phote1} className='object-contain w-[140px] h-[140px] top-0' />
                <img src={Phote1} className='object-contain w-[140px] h-[140px] top-0 opacity-0' />
                <img src={Phote2} className='object-contain w-[140px] h-[140px] absolute top-[30%] -left-[90px]' />
                <img src={Phote3} className='object-contain w-[140px] h-[140px] bottom-0' />
              </div> */}

              <div className='w-full flex flex-col gap-8 py-16 h-full'>
                <Slider {...settings}>
                  {ServiceTestimonials.map((item, index) =>
                    <div className='relative'>
                      <div className={`flex flex-col space-y-5 items-center h-full  justify-between`}>
                        <div style={{ backgroundImage: `url(${item.img})` }} className='bg-no-repeat relative bg-center border-[3px] align-top rounded-full border-white bg-cover shadow-xl w-[180px] inline-block h-[180px]' >
                          <div className='absolute w-[60px] h-[60px] rounded-full shadow-lg bg-gradient-to-r from-[#ff3834] to-[#ff7133] bottom-[-10px] right-[-5px] flex flex-col items-center justify-center'>
                            <img className='w-[25px] h-[25px] object-contain' src={QuoteIcon} />
                          </div>
                        </div>
                        <img className='absolute right-[10%] top-[16%]' src={RoundIcon} />
                        <blockquote>
                          <p className='text-center'>{item.headerDescription} </p>
                        </blockquote>
                        <div className='mb-1 capitalize font-medium  text-black text-lg not-italic'>{item.name}</div>
                        <div className='capitalize font-medium  text-[#104cba] text-base not-italic'>{item.role}</div>
                      </div>
                    </div >
                  )}
                </Slider>
              </div>
              {/* <div className=' flex-col space-y-5 w-[15%] justify-between relative h-full lg:flex hidden'>
                <img src={Phote4} className='object-contain w-[140px] h-[140px] top-0' />
                <img src={Phote4} className='object-contain w-[140px] h-[140px] top-0 opacity-0' />
                <img src={Phote5} className='object-contain w-[140px] h-[140px] absolute top-[30%] -right-[90px]' />
                <img src={Phote6} className='object-contain w-[140px] h-[140px] bottom-0' />
              </div> */}
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default Products;