import React, { useRef } from 'react'
import Logo from "../Image/Home1/main-isllogo.png";
import { GrFacebookOption } from "react-icons/gr";
import { FaTwitter } from "react-icons/fa";
import { FaPinterestP } from "react-icons/fa";
import { BiLogoLinkedin } from "react-icons/bi";
import { Link, useNavigate } from 'react-router-dom';
import { IoChevronForward } from "react-icons/io5";

function Footer({ handleClick }) {
    // const SocialMedia = [
    //     {
    //         icon: <GrFacebookOption />,
    //         color: "#395398"
    //     },
    //     {
    //         icon: <FaTwitter />,
    //         color: "#1E94E9"
    //     },
    //     {
    //         icon: <BiLogoLinkedin />,
    //         color: "#046EB0"
    //     },
    //     {
    //         icon: <FaPinterestP />,
    //         color: "#BB2032"
    //     }
    // ]
    const FooterMeneu = [
        {
            name: "Home",
            route: "/",
        },
        {
            name: "About",
            route: "/About",
        },

        // {
        //     name: "Web Designing",
        //     route: "/Web_Designing"
        // },
        {
            name: "Mobile Applications",
            route: "/mobile_apps"
        },
        {
            name: "Game Development",
            route: "/Game_Development"
        },
        {
            name: "Clients",
            route: "/Clients",
        },
    ]
    const Terms = [
        {
            name: "Privacy Policy",
            route: "PrivacyPolicy"
        },
        {
            name: "Terms & Conditions",
            route: "TermsConditions"

        },
        {
            name: "Refund And Cancellation Policy",
            route: "RefundPolicy"

        },
        {
            name: "Payments Policy",
            route: "PaymentsPolicy"

        },
    ]
    const navigate = useNavigate();

    return (
        <div className=' flex flex-col items-center justify-center bg-gradient-to-r from-[#30077d] to-[#095ac6]'>
            <div className='max-w-[1200px] mx-auto p-3 h-full w-full'>
                <div className='grid lg:grid-cols-7 grid-cols-1 gap-6 h-full py-20'>
                    <div className='col-span-2 flex flex-col  gap-3  text-white text-base relative p-2  px-5'>
                        <img src={Logo} className='w-[180px] object-contain h-12' alt='Logo' />
                        <div className='text-[15px] leading-7'>
                            Whether your business needs a website, eCommerce website, a Mobile / Web Application, Game , AR & VR or any other services; Illusion Spot Line will provide you excellent customer service
                        </div>
                        <div className='flex flex-row items-center gap-5'>
                            <div className={`flex flex-col items-center justify-center p-[10px] cursor-pointer bg-[#395398] rounded-full hover:opacity-75 hover:bg-[#395398] hover:duration-200`}>
                                <a href=''>
                                    <GrFacebookOption />
                                </a>
                            </div>
                            <div className={`flex flex-col items-center justify-center p-[10px] cursor-pointer bg-[#1E94E9] rounded-full hover:opacity-75 hover:bg-[#1E94E9] hover:duration-200`}>
                                <a href=''>
                                    <FaTwitter />
                                </a>
                            </div>
                            <div className={`flex flex-col items-center justify-center p-[10px] cursor-pointer bg-[#046EB0] rounded-full hover:opacity-75 hover:bg-[#046EB0] hover:duration-200`}>
                                <a href=''>
                                    <BiLogoLinkedin />
                                </a>
                            </div>
                            <div className={`flex flex-col items-center justify-center p-[10px] cursor-pointer bg-[#BB2032] rounded-full hover:opacity-75 hover:bg-[#BB2032] hover:duration-200`}>
                                <a href=''>
                                    <FaPinterestP />
                                </a>
                            </div>

                        </div>
                    </div>
                    <div className='col-span-1 flex flex-col gap-4'>
                        <div className='text-white text-lg font-semibold mt-3'>Quick Links </div>
                        {FooterMeneu.map((header, index) =>
                            <div onClick={() => handleClick(header.route)} key={index}>
                                <Link to={header.route} className="font-normal text-base text-white cursor-pointer"> {header.name}</Link>
                            </div>
                        )}
                    </div>
                    <div className='col-span-2 flex flex-col gap-4'>
                        <div className='text-white text-lg font-semibold mt-3'>Policy</div>
                        {Terms.map((Policy, index) =>
                            <div onClick={() => handleClick(Policy.route)} key={index}>
                                <Link to={Policy.route} className="font-normal whitespace-nowrap text-base text-white cursor-pointer"> {Policy.name}</Link>
                            </div>
                        )}
                    </div>
                    <div className='col-span-2 flex flex-col gap-4  text-white text-base'>
                        <div className='text-white text-lg font-semibold mt-3'>Contact Us</div>
                        <div>No 22, Padi Pudu Nagar, Main Road Anna Nagar West Ext, Chennai</div>
                        <div>info@illusionspotline.com</div>
                        <div>+91 79047 04039</div>
                        <div>+91 99522 98666</div>
                    </div>

                </div>
                <hr className='border-b-[1px] border-gray-200 w-full' />
            </div>
            <div className='max-w-[1200px] mx-auto p-3 h-full w-full text-white text-center pb-3'>
                ©2022 Illusion Spot Line . All rights reserved
            </div>
        </div>
    )
}

export default Footer