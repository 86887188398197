
export const Questions = [
    {
        title: "Any Query",
        description: "Frequently Asked questions",
        query: [
            {
                header: "Turn-Key",
                headerDescription: "Our highly experienced animators have the passion, skill, and expertise to drive your project forward from; concept-to-completion, we move your idea forward with innovation and speed.",
            },
            {
                header: "Always Available",
                headerDescription: "We are always available to service your needs. You can message or call us 24/7 and you’ll always be able to get a hold of us the same day. We work late and weekends just to make sure your project exceeds your expectations every time.",
            },
            {
                header: "Full Support Team",
                headerDescription: "We are fully staffed with 3D Artist, Producers, Cartoonists, Illustrators, Web Developer, and Marketing and Branding Industry Experts to ensure your project is a complete success every time. We are proud to be the largest 3D Animation Studio is ISL. Our state-of-the-art animation pipeline, allows Isl to offer a much wider variety of related services than our traditional competitors. ISL is a full-service, concept-to-launch facility. We can create any kind of Animation, Design, illustration, Video Production, FX or graphics you can imagine. If you can dream of it, we can bring it to life. We transform your ideas into reality.",
            },
            {
                header: "100% Satisfaction Guarantee",
                headerDescription: "We’re so confident you’ll be Wow’d by our services every time, we guarantee that you’ll be 100% satisfied with the Custom Animation and Video we make for you! It’s just that simple. This is an offer you won’t find at other 3D Animation Studios.",
            },
          
        ]
    },
]