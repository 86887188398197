import { BsBarChart } from "react-icons/bs";
import { RxGear } from "react-icons/rx";
import { LuFileBarChart2 } from "react-icons/lu";
import fadeIn from '../variants';


export const BusinessGoals= [
    {
        img: <BsBarChart/>,
        title: "Vision",
        description: "We are moving towards virtual world where things can be tested virtually before becoming real. It is time for the architectural visualization to go interactive. its product visualization work through precision modeling, photo realistic lighting and texturing, and cinematic animation.",
        variants:fadeIn("top", 0.3)
    },
    {
        img: <LuFileBarChart2/>,
        title: "Team",
        description: "90% of our clients keep coming back, and 90% of our people stay with us. We work hard to earn this kind of loyalty in every aspect of our business, whether it’s the quality of our market offerings or the professional growth opportunities for our team members. The energy and resources that we invest in our teams translate directly into the energy and dedication that our teams bring to client relationships.",
        variants:fadeIn("top", 0.4)
    },
    {
        img: <RxGear/>,
        title: "Client Testimonials",
        description: "Some of our happy clients explaining about their experience with ISL team. We are proud to provide them with successful for business. We satisfying our clients every needs and requirements. we prioritize our clients deadlines and project completion dates.",
        variants:fadeIn("top", 0.5)
    },
    {
        img: <BsBarChart/>,
        title: "Our Approach",
        description: "We are very enthusiastic about our work and deliver business more than their expectations and brilliant up-to-date web solutions. We pride ourselves to be one of the best website design company as we recognize our client needs and deliver unique and professional web designing services. Unlike many web designing companies we do not solely focus on work of art but passionate about user experience and the return on investment.",
        variants:fadeIn("bottom", 0.3)
    },
    {
        img: <BsBarChart/>,
        title: "Our Ethics",
        description: "We believe not to be less than the best and love to work with clients, who are quite happy with our working style. We create long lasting relationship with our esteemed clients and support their needs over years. We genuinely share our knowledge, experience and expertise, as well as results driven web solution for any business at the best.",
        variants:fadeIn("bottom", 0.4)
    },
    {
        img: <RxGear/>,
        title: "Security",
        description: "Your security and privacy are of the utmost importance to us. Our systems are continually backed up by secure servers and our infrastructure remains secure behind some of the best firewalls in the industry and we routinely sign non-disclosure agreements so that your project can remain completely confidential for as long as you like. You can rest assured, you’ll never lose a file with us and your privacy will always be respected by us.",
        variants:fadeIn("bottom", 0.5)
    },
  
]