import { BsBarChart } from 'react-icons/bs';
import ServiceImage from '../Assert/vec01-1-7.png';
import tickIcon from '../Assert/Year/icon01-7.png';
import fadeIn from '../variants';
import { LuFileBarChart2 } from 'react-icons/lu';
import { RxGear } from 'react-icons/rx';

export const BrandingData = [
    {
        img: ServiceImage,
        company: "PLANNING",
        title: "Developing Powerful Brands and Identities",
        description1: "We’ve been creating strong brands for both socially and environmentally conscious companies thus creating a strong footprint for our clients.",
        description2: "We are just a bunch of determined geeks with out-of-the-box ideas. Creativity is our drug and we certainly cannot live without it. Our proficient and adept team strives to ensure that our branding will be remembered forever and ever. We believe that a brand is an unifying, inspiring and everlasting experience. We at Brand mustache nurture and develop a good idea into a solid brand while executing all this without compromising on quality.",
        description3: "We have experienced designers that can produce creative logo design and corporate logo designs. We work hard to get an insight into your company and then offer options for you to select from in order to design a perfect logo suitable for marketing your brand. Afterwards we develop the logo and help you to integrate it wherever needed.",
        // content : [
        //     {
        //         img1: tickIcon,
        //         header: "Content strategy",
        //         headerDescription: "Maecenas ipsum suscipit",
        //         variants:fadeIn("left", 0.2)
        //     },
        //     {
        //         img1: tickIcon,
        //         header: "IT Management",
        //         headerDescription: "Maecenas ipsum suscipit",
        //         variants:fadeIn("right", 0.2)
        //     },
        //     {
        //         img1: tickIcon,
        //         header: "Work Dedication",
        //         headerDescription: "Maecenas ipsum suscipit",
        //         variants:fadeIn("left", 0.2)
        //     },
        //     {
        //         img1: tickIcon,
        //         header: "Time Management",
        //         headerDescription: "Maecenas ipsum suscipit",
        //         variants:fadeIn("right", 0.2)
        //     },

        // ]
    },
]
export const ConnectedExperiencesData = [
    {
        img: <BsBarChart />,
        title: "Connected Experiences",
        description: "Brand experiences focus as much on the connections between touch points as the touch points themselves.Brand experience strategy and design optimists the journey around customer needs and goals across touch points and channels creating a connected brand experience.",
        variants: fadeIn("left", 0.3)
    },
    {
        img: <LuFileBarChart2 />,
        title: "Design & Creative",
        description: "Brand experiences focus as much on the connections between touch points as the touch points themselves.Brand experience strategy and design optimists the journey around customer needs and goals across touch points and channels creating a connected brand experience.",
        variants: fadeIn("right", 0.4)
    },
    {
        img: <RxGear />,
        title: "Product & Service",
        description: "We bring together customer experience designers and industrial designers to rapidly prototype and deliver products and services that deliver on customer and business needs. Our capabilities include category and consumer insights, customer journey mapping, prototyping, business case development, industrial design and manufacturing, service design and delivery.",
        variants: fadeIn("left", 0.5)
    },
    {
        img: <BsBarChart />,
        title: "Our Philosophy",
        description: "We are a leader in innovation, quality, efficiency and customer satisfaction. We operate with complete integrity, focus entirely on the business needs of our customers and treat our target audiences with the dignity and respect they rightly deserve. Our future will be determined by our ability to satisfy our employees and provide our clients with great value by producing the finest products backed by consistently top-quality service.",
        variants: fadeIn("right", 0.3)
    }
]