import React from 'react'
import Banner from '../Assert/Team/banner-8.webp';
import { HiChevronRight } from "react-icons/hi";


function BannersComponent(props) {
    return (
        <div style={{ backgroundImage: `url(${Banner})` }} className='bg-no-repeat bg-cover flex flex-col space-y-5 items-center justify-center bg-center h-[300px]'>
            <div className='lg:text-[50px] text-4xl capitalize font-bold text-white leading-[58px]'>{props.header}</div>
            <div className='flex flex-row items-center space-x-2 leading-7 font-normal text-white text-base capitalize font-Nunito'>
                <a href='/'> Home </a>
                <HiChevronRight className='text-base font-semibold'/>
               <div>{props.title}</div>
            </div>
        </div>
    )
}

export default BannersComponent