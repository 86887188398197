import React, { useContext } from 'react'
import { BottomContent } from '../../App';

function RefundPolicy() {
  const UseBottomContext = useContext(BottomContent);
  return (
    <div ref={UseBottomContext} className='py-10'>
      <div className='max-w-[1200px] mx-auto p-3 flex flex-col gap-3'>
        <div className='text-3xl self-center capitalize font-semibold text-black'>Refund And Cancellation Policy
        </div>
        <p>All Credits purchased are non-refundable except as provided for in this paragraph 8. We will not, for example, offer any return of Credits if the Contact(s) we provide you with are correct, but where the Prospective Customer chooses not to respond to your introduction, or if you do not secure the job with the Prospective Customer. There may be cases where a Prospective Customer decides not to choose any of the professionals we introduce them to, and this is entirely at the discretion of the Prospective Customer. In these instances, no refund of Credits would apply.</p>
        <div className='text-lg font-semibold text-[#104cba]'>Refund Policy

        </div>
        <p>There is no refund avialable based on the privacy policy. For more details contact us at info@illusionspotline.com through email or call us on +91 79047 04039 /

          +91 99522 98666.
        </p>
      </div>
    </div>
  )
}

export default RefundPolicy