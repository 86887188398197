import React, { useContext } from 'react';
import { motion } from "framer-motion";
import fadeIn from '../variants';
import Shape01 from '../Assert/Year/Shape01.webp';
import { BottomContent } from '../App';
import GameImage from "../Assert/Service/Game.png"
import BannersComponent from '../Constant/BannersComponent';
import Button from '../Constant/Button';
import LightDarkBanner from '../Assert/Service/Img_13.webp';
import GetInTouch from '../Assert/vec03-7.png';
import Home5 from '../Assert/Home5-17-7.png';
import Home7 from '../Assert/Home5-15-7.png';
import Banner from '../Assert/Ban.png';

function GameDevelopment() {
  const UseBottomContext = useContext(BottomContent);

  return (
    <div ref={UseBottomContext} className='flex flex-col  '>
      <BannersComponent header="Game Development" title="Game" />
      <div className='flex flex-col py-10 gap-5'>
        <div style={{ backgroundImage: `url(${Shape01})` }} className='bg-no-repeat bg-contain  bg-left  overflow-hidden'>
          <div className='max-w-[1200px] mx-auto p-3'>
            <div className='grid lg:grid-cols-2 gap-5'>
              <div className='col-span-1 flex flex-col lg:items-start items-center'>
                <img className='object-contain h-[90%] w-full' src={GameImage} />
              </div>
              <div className='flex flex-col space-y-5 col-span-1'>
                <motion.div variants={fadeIn("bottom", 0.3)} initial="hidden" whileInView={"visible"} className='text-lg font-semibold text-[#104cba]'>PLANNING</motion.div>
                <motion.div variants={fadeIn("bottom", 0.4)} initial="hidden" whileInView={"visible"} className='text-3xl capitalize font-semibold text-black'>Game Development Services
                </motion.div>
                <motion.p variants={fadeIn("bottom", 0.5)} initial="hidden" whileInView={"visible"} className='text-base text-[#828282]'>Web and mobile platforms. From side-scrolling 2D platformers to multi-player RPGs with VR integration to casual online, social and mobile games we’ve designed and developed a number of fun and exciting titles. We adapt to the latest tools and frameworks in the market and work with game engines like Unity3D, Cocos2d-x and Unreal as well as technologies like HTML5, Angular JS and others to build high quality games
                </motion.p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundImage: `url(${LightDarkBanner})` }} className='bg-no-repeat bg-cover flex flex-col overflow-hidden'>
        {/* <div className='max-w-[1200px] mx-auto p-3 relative'>
            <div className='flex flex-col space-y-10'>
              <div className='flex flex-col items-center justify-center space-y-5'>
                <motion.div variants={fadeIn("bottom", 0.2)} initial="hidden" whileInView={"visible"}  className='text-lg font-semibold text-[#104cba]'>Featured Services</motion.div>
                <motion.div variants={fadeIn("bottom", 0.4)} initial="hidden" whileInView={"visible"}  className='text-3xl capitalize text-center font-semibold text-black'>Engaging Creative minds via technology
                </motion.div>
              </div>
              <FeaturedServicesCard />
              <img className='w-6 h-6 object-contain left-[70%] absolute -bottom-[20px]' src={Banner} />
            </div>
          </div> */}
        <div className='max-w-[1200px] mx-auto p-3 w-full relative flex flex-col gap-10 py-12'>
        <img className='left-[65%] absolute top-0' src={Banner} />
          <div className='flex flex-row lg:flex-nowrap flex-wrap  justify-between w-full gap-5'>
            <div className='lg:w-[46%] w-full'>
              <motion.img variants={fadeIn("left", 0.2)} initial="hidden" whileInView={"visible"} src={GetInTouch} />
            </div>
            <img className='absolute left-[46%]' src={Home5} />
            <div className='flex flex-col lg:w-[48%] w-full space-y-5'>
              <motion.div variants={fadeIn("bottom", 0.3)} initial="hidden" whileInView={"visible"} className='text-lg font-semibold text-[#104cba]'>Get in Touch              </motion.div>
              <motion.div variants={fadeIn("bottom", 0.4)} initial="hidden" whileInView={"visible"} className='text-4xl capitalize font-semibold text-black'>we provide best services  <br />Need Help? </motion.div>
              <motion.div variants={fadeIn("bottom", 0.6)} initial="hidden" whileInView={"visible"} className='flex flex-col space-y-4'>
                <input placeholder='Your Name *' />
                <input placeholder='Your Email *' />
                <textarea className='textarea' placeholder='Messages *' />
                <Button name="Send Message" />
              </motion.div>
            </div>
          </div>
        </div>
        <div className='max-w-[1200px] mx-auto p-3 w-full'>
          <div className='flex flex-row gap-5'>
            <div className='lg:w-[46%] w-full'>
            </div>
            <div className='lg:w-[46%] w-full flex flex-col items-center justify-center'>
              <img src={Home7} />
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default GameDevelopment;