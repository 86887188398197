import React, { useContext, useState } from 'react';
import fadeIn from '../variants';
import { motion } from "framer-motion";
import Button from '../Constant/Button';
import { FaPhoneVolume } from "react-icons/fa6";
import Phone from '../Assert/Home/Phone.png';
import SecurePayment from '../Assert/Home/SecurePayment.png';
import Shape1 from '../Assert/Home/Shape-1.png';
import Shape2 from '../Assert/Home/Shape-2.png';
import Dot from '../Assert/Home/dot-18.png';
import SideIcon1 from '../Assert/Home/side-icon-1.png';
import SideIcon2 from '../Assert/Home/side-icon-2.png';
import SideIcon3 from '../Assert/Home/side-icon-3.png';
import SideIcon4 from '../Assert/Home/side-icon-4.png';
import Banner from '../Assert/Home/banner-bg.webp';
import RightBanner from '../Assert/Home/Ban-03-18.png';
import tickIcon from '../Assert/tick-7.png';
import FinanceImage from '../Assert/Home/Finance.webp'
import ProjectImage from '../Assert/Home/home-18.png'
import CanadaImage from '../Assert/Home/home-19.webp'
import RangeOfServicesCard from '../Constant/RangeOfServicesCard';
import { TeamMemberData } from '../utils/TeamMemberData';
import TeamMemberCard from '../Constant/TeamMemberCard';
import SoundImage from '../Assert/Home/sounds-like-softek.png'
import WorldClassImage from '../Assert/Home/WorldClass.png'
import SoftwareImage from '../Assert/Home/Ellipse-28.png'
import Phone1 from '../Assert/Home/Phone1.webp'
import Phone2 from '../Assert/Home/Phone2.webp'
import { BottomContent } from '../App';
import { useNavigate } from 'react-router-dom';
import { expertise } from '../utils/ClientData';
import { OurProjectData } from '../utils/OurProjectData';
import { technologyList } from '../utils/TechnologiesData';
import Slider from 'react-slick';


function Home() {
    const WeDo = [
        {
            img: SideIcon2,
            name: "Web applications development",
            description: "We develop customized, and domain specific web application to meet your demands cost-effectively.",
            color: "#486C00",
            variants: fadeIn("bottom", 0.2)

        },
        {
            img: SideIcon3,
            name: "Mobile application development",
            description: "Get end-to-end mobile app development from business analysis to deployment.",
            color: "#DA7D06",
            variants: fadeIn("bottom", 0.3)

        },
        {
            img: SideIcon1,
            name: "Custom software development",
            description: "Get a custom solution developed tailored to your requirements to elevate your business profits.",
            color: "#104cba",
            variants: fadeIn("bottom", 0.4)
        },
        {
            img: SideIcon4,
            name: "Games development",
            description: "Get end-to-end 2D and 3D animated games development we’re designing games based on the requirements and also for the every age",
            color: "#F8496F",
            variants: fadeIn("bottom", 0.5)
        },
    ]
    const [showSocial, setShowSocial] = useState(-1)
    const d = [
        {
            img1: tickIcon,
            header: "Digital Consultancy",
            headerDescription: "Unknown there are many variations of spassagesnof lorem Ipsum available internet tend to repeat.",
        },
        {
            img1: tickIcon,
            header: "Project Management",
            headerDescription: "Unknown there are many variations of spassagesnof lorem Ipsum available internet tend to repeat.",
        }
    ]
    const SoftwareDevelopment = [
        {
            header: "Set everything up in minutes",
            headerDescription: "Ultrices gravida risus dole commodo viverral maecenas accumsan goes has industryu2019s standard ever.",
            bgColor: "w-[80px] h-[45px] rounded-full bg-gradient-to-r from-[#2f0b81] text-white to-[#0c56c4] flex flex-col items-center justify-center shadow-lg"
        },
        {
            header: "A seamless UI experience",
            headerDescription: "Ultrices gravida risus dole commodo viverral maecenas accumsan goes has industryu2019s standard ever.",
            bgColor: "w-[80px] h-[45px] rounded-full bg-white hover:bg-gradient-to-r from-[#2f0b81] shadow-lg transition-all duration-200 hover:text-white text-black to-[#0c56c4] flex flex-col items-center justify-center"
        }
    ]
    const UseBottomContext = useContext(BottomContent);
    const navigate = useNavigate();
    const settings = {
        dots: false,
        speed: 2000,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div ref={UseBottomContext} className='flex flex-col'>
            <div className='relative flex flex-col items-center'>
                <img className='w-full object-center lg:h-[780px] h-[600px]' src={Banner} />
                <div className='max-w-[1200px] mx-auto p-3 top-0 absolute w-full h-[600px]'>
                    <section className='flex flex-row lg:flex-nowrap flex-wrap justify-between w-full gap-5 h-full'>
                        <div className='flex flex-col space-y-5 lg:w-[48%] w-full justify-center h-full'>
                            <div className='text-lg font-semibold text-[#104cba]'>App development made easy
                            </div>
                            <div className='text-3xl capitalize font-semibold text-black'>No matter the complexity,<br /> we can build your app</div>
                            <p> Leveraging over 12+ years of experience in driving digital transformation worldwide, we craft innovative digital solutions that align with your unique business needs and enhance your digital customer journey.
                            </p>

                            <div className='flex lg:flex-row lg:gap-10 gap-5 lg:items-center flex-col lg:flex-nowrap flex-wrap'>
                                <Button name="Get Started" />
                                <div className='flex flex-row items-center  space-x-5'>
                                    <FaPhoneVolume className='text-[#ff7133] text-xl' />
                                    <div className='flex flex-col space-y-1'>
                                        <div className='text-[#104cba] text-lg font-semibold'>Call us +91 9952298666</div>
                                        <p>For any questions </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='lg:w-[48%] w-full relative xl:flex hidden'>
                            <img className='absolute top-0 xl:w-[360px] xl:h-[370px] lg:w-[300px]  left-[6%]' src={Shape2} />
                            <img className='absolute xl:w-[320px] xl:h-[360px] lg:w-[300px] top-[40%] left-[50%]' src={Shape1} />
                            <img className='absolute top-[120px] left-[24%]' src={Phone} />
                            <img className='absolute z-40 top-[245px] hover:-translate-y-2 transition-all duration-500' src={SecurePayment} />
                        </div>
                    </section>
                </div>
            </div>
            <div style={{ backgroundImage: `url(${RightBanner})` }} className='py-20 bg-no-repeat overflow-hidden  bg-right'>
                <div className='max-w-[1200px] mx-auto p-3'>
                    <section className='flex flex-row lg:flex-nowrap flex-wrap justify-between w-full gap-5 '>
                        <div className='flex flex-col space-y-5 relative lg:w-[40%] w-full justify-center'>
                            <motion.div initial={{ opacity: 0 }} transition={{ delay: 0.3, duration: 1 }} animate={{ opacity: 1, scaleZ: 3 }} className='text-lg font-semibold text-[#104cba]'>What We Do </motion.div>
                            <motion.div initial={{ opacity: 0 }} transition={{ delay: 0.4 }} animate={{ opacity: 1, scaleZ: 3 }} className='text-3xl capitalize font-semibold text-black'>Software & Mobile Application </motion.div>
                            <motion.p initial={{ opacity: 0 }} transition={{ delay: 0.5 }} animate={{ opacity: 1, scaleZ: 3 }}>
                                Whether your business needs a website, eCommerce website, a Mobile / Web Application, Game , AR & VR or any other services; Illusion Spot Line will provide you excellent customer service, expert marketing insight, and team is a diverse network of consultants and industry professionals with a global mindset and a collaborative culture. We work to understand your issues and are driven to ask better questions in the pursuit of making your business work better.
                            </motion.p>
                            <img className='absolute w-40 h-56 bottom-[30px] right-0' src={Dot} />
                            <motion.div initial={{ opacity: 0 }} transition={{ delay: 0.6 }} animate={{ opacity: 1, scaleZ: 3 }} onClick={() => navigate("/About")} className='slideUp'>
                                <Button name="Discover More" />
                            </motion.div>
                        </div>
                        <div className='lg:w-[56%] w-full'>
                            <div className='grid md:grid-cols-2 grid-cols-1  gap-5'>
                                {WeDo.map((item, index) =>
                                    <motion.div style={{ backgroundColor: showSocial == index ? item.color : "" }} variants={item.variants} initial="hidden" whileInView={"visible"} key={index} onPointerEnter={() => setShowSocial(index)} onPointerLeave={() => setShowSocial(-1)} className={`shadow-md bg-white ${showSocial == index ? `transition-all duration-700` : ``} py-[20px] px-[20px] col-span-1 flex flex-col gap-5 rounded-lg`}>
                                        <div className='flex flex-row items-center space-x-5'>
                                            <img src={item.img} />
                                            <div className={`text-lg font-semibold text-[#104cba] ${showSocial == index ? `text-white` : ``}`}>{item.name}</div>
                                        </div>
                                        <p className={`${showSocial == index ? `text-white` : ``} text-justify `}>{item.description}</p>
                                    </motion.div>)}
                            </div>
                        </div>
                    </section>
                </div>
                {/* <div className='max-w-[1200px] mx-auto p-3  py-20'>
                    <section className='flex flex-row lg:flex-nowrap flex-wrap justify-between w-full gap-5'>
                        <div className='lg:w-[48%] w-full relative'>
                            <img className='absolute right-[60px] top-[50px]' src={CanadaImage} />
                            <img src={FinanceImage} />
                            <img className='absolute left-[40px] -bottom-[50px]' src={ProjectImage} />
                        </div>
                        <div className='flex flex-col space-y-5 relative lg:w-[48%] w-full justify-between lg:mt-0 mt-20'>
                            <div className='text-lg font-semibold text-[#104cba]'>Finance Solutions                            </div>
                            <div className='text-3xl capitalize font-semibold text-black'>Breaking The Ice ConceptnFor A Business Transparency
                            </div>
                            <p>Ever since when an unknown there are many variations of lorem spassages of Lorem Ipsum available internet tend to repeat.
                            </p>
                            {d.map((item, index) =>
                                <motion.div variants={fadeIn("right", 0.4)} initial="hidden" whileInView={"visible"}  key={index} className='flex flex-row items-start space-x-5 '>
                                    <img className='w-[28px] h-[28px] object-contain  hover:-translate-x-4 transition-all duration-150' src={item.img1} />
                                    <div className='flex flex-col space-y-2'>
                                        <div className='text-lg text-black font-semibold'>
                                            {item.header}
                                        </div>
                                        <p>
                                            {item.headerDescription}
                                        </p>
                                    </div>
                                </motion.div>
                            )}

                            <Button name="Discover More" />
                        </div>
                    </section>
                </div> */}
            </div>
            <div className='py-10 bg-[#141e6f]'>
                <div className='max-w-[1200px] mx-auto p-3 bg-[#141e6f] px-3 py-5'>
                    <div className='flex flex-row lg:flex-nowrap flex-wrap items-center justify-between w-full gap-5 '>
                        <div className='flex flex-col space-y-4 lg:w-[75%] w-full'>
                            <motion.div initial={{ opacity: 0, }} whileInView={{
                                opacity: 12,
                            }} transition={{ delay: 0.3, duration: 1 }} className='text-4xl font-semibold capitalize not-italic text-white'>It’s Not Just Software, But Your Growth Counselor</motion.div>

                            <motion.div initial={{ opacity: 0, }} whileInView={{
                                opacity: 1,
                            }} transition={{ delay: 0.5, duration: 1 }} className='text-base font-normal leading-7 capitalize not-italic text-white'>
                                We craft business inventory management software that goes beyond your expectations and functions more like a guide, friend, and philosopher for your business.
                            </motion.div>
                        </div>
                        <motion.div className='lg:w-[20%] w-full' initial={{ opacity: 0, }} whileInView={{
                            opacity: 1,
                        }} transition={{ delay: 0.9, duration: 1 }} >
                            <Button name="Get Started" />
                        </motion.div>
                    </div>
                </div>
            </div>
            <div className='py-20 TeamBackgroundColor'>
                <div className='max-w-[1200px] mx-auto p-3 w-[93%] flex flex-col  space-y-5'>
                    <div className='flex flex-col items-center justify-center space-y-5'>
                        <motion.div variants={fadeIn("bottom", 0.2)} initial="hidden" whileInView={"visible"} className='text-lg font-semibold text-[#104cba]'>Our Projects  </motion.div>

                    </div>
                    <motion.div variants={fadeIn("bottom", 0.3)} initial="hidden" whileInView={"visible"} >

                        <TeamMemberCard data={OurProjectData} />
                    </motion.div>
                </div>
            </div>
            <div className='py-20'>
                <div className='max-w-[1200px] mx-auto p-3 '>
                    <div className='flex flex-col space-y-10'>
                        <div className='flex flex-col items-center justify-center space-y-5'>
                            <motion.div variants={fadeIn("bottom", 0.2)} initial="hidden" whileInView={"visible"} className='text-lg font-semibold text-[#104cba]'>How We Do
                            </motion.div>
                            <motion.div variants={fadeIn("bottom", 0.4)} initial="hidden" whileInView={"visible"} className='text-3xl self-center capitalize font-semibold text-black'>We Offer A Wide Range Of Services
                            </motion.div>
                            <motion.p variants={fadeIn("bottom", 0.6)} initial="hidden" whileInView={"visible"} className='text-center'>Making this the first true generator on the Internet. It uses a dictionary of over combined with a <br /> handful of model sentence structures generate
                            </motion.p>
                        </div>
                    </div>
                    <div className='mt-16'>
                        <RangeOfServicesCard />
                    </div>
                </div>
            </div>
            {/* <div className='py-20'>
                <div className='max-w-[1200px] mx-auto p-3 '>
                    <section className='flex flex-row lg:flex-nowrap flex-wrap justify-between w-full gap-5 '>
                        <div className='flex flex-col space-y-5 relative lg:w-[48%] w-[90%] justify-center'>
                            <div className='text-lg font-semibold text-[#104cba]'>Software Development</div>
                            <div className='text-3xl capitalize font-semibold text-black'>we're software company that provides solutions </div>
                            <p>
                                Making this the first true generator on the Internet. It uses a dictionary of over combined with a handful of model sentence structures generate
                            </p>
                            {SoftwareDevelopment.map((item, index) =>
                                <motion.div variants={fadeIn("bottom", 0.1)} initial="hidden" whileInView={"visible"}  key={index} className='flex flex-row items-start space-x-5'>
                                    <div className={`${item.bgColor}`}>{index + 1}</div>
                                    <div className='flex flex-col space-y-5'>
                                        <div className='text-lg text-[#333] font-semibold capitalize not-italic'>
                                            {item.header}
                                        </div>
                                        <p>
                                            {item.headerDescription}
                                        </p>
                                    </div>
                                </motion.div>
                            )}

                        </div>
                        <div className='lg:w-[48%] w-full flex flex-col items-center justify-center relative'>
                            <img className='object-contain absolute lg:-left-[2px] left-[10%] lg:top-[25px] top-[7px] w-[331px] h-[565px] z-20  hover:-translate-y-3 transition-all duration-500' src={Phone1} />
                            <img className='object-contain' src={SoftwareImage} />
                            <img className='object-contain absolute lg:right-0 right-[14%]  top-[20px] hover:-translate-y-3 transition-all duration-500 w-[303px] h-[535px]' src={Phone2} />
                        </div>
                    </section>
                </div>
            </div> */}
            {/* <div style={{ backgroundImage: `url(${RightBanner1})` }} className='bg-no-repeat bg-contain bg-right'>
                <div className='max-w-[1200px] mx-auto p-3  py-20'>
                    <section className='flex flex-row lg:flex-nowrap flex-wrap justify-between w-full gap-5'>
                        <div className='lg:w-[48%] w-full relative'>
                            <img className='absolute left-0 top-0' src={WeekImage} />
                            <img className='w-[517px] h-[521px] object-contain' src={TechnologyImage} />
                            <img className='absolute left-[120px] -bottom-[30px]' src={TrustedImage} />
                        </div>
                        <div className='flex flex-col space-y-5 relative lg:w-[48%] w-full lg:mt-0 mt-20'>
                            <motion.div variants={fadeIn("bottom", 0.2)} initial="hidden" whileInView={"visible"}  className='text-lg font-semibold text-[#104cba]'>Technology Solutions
                            </motion.div>
                            <motion.div variants={fadeIn("bottom", 0.3)} initial="hidden" whileInView={"visible"}  className='text-3xl capitalize font-semibold text-black'>Your Website Needs FromnStart-Up To Success
                            </motion.div>
                            <motion.p variants={fadeIn("bottom", 0.4)} initial="hidden" whileInView={"visible"} >Making this the first true generator on the Internet. It uses a dictionary of over combined with a handful of model sentence generate.

                            </motion.p>
                            <motion.div variants={fadeIn("bottom", 0.5)} initial="hidden" whileInView={"visible"}  className='w-full flex flex-row items-center gap-5 justify-between  shadow-2xl h-[45%] p-5 bg-white rounded-md'>
                                <div className='flex flex-col space-y-3'>
                                    <div className='text-[32px] text-[#ff6333] font-semibold not-italic'>
                                        25+
                                    </div>
                                    <div className='text-lg text-black font-semibold'>
                                        Years Of Experience
                                    </div>
                                    <p >Generator on the Internet Itnuses a dictionary over combined.</p>
                                </div>
                                <div className='h-full border-[1px]' />
                                <div className='flex flex-col space-y-3'>
                                    <div className='text-[32px] text-[#ff6333] font-semibold not-italic'>
                                        98%

                                    </div>
                                    <div className='text-lg text-black font-semibold'>
                                        Possitive Feedback

                                    </div>
                                    <p >Generator on the Internet Itnuses a dictionary over combined.</p>
                                </div>
                            </motion.div>
                        </div>
                    </section>
                </div>
            </div> */}
            <div className='py-16 TeamBackgroundColor shadow-md'>
                <div className='max-w-[1200px] mx-auto p-3 w-[93%] flex flex-col  space-y-8'>
                    <div className='flex flex-col items-center justify-center space-y-5'>
                        <motion.div variants={fadeIn("bottom", 0.2)} initial="hidden" whileInView={"visible"} className='text-lg font-semibold text-[#104cba]'>T E C H N O L O G I E S
                        </motion.div>
                    </div>
                    <motion.div variants={fadeIn("bottom", 0.3)} initial="hidden" whileInView={"visible"} >
                        <Slider {...settings}>
                            {technologyList.map((item, index) =>
                                <img key={index} className='w-[140px] h-[80px] object-contain  rounded-t-lg' src={item} />
                            )}
                        </Slider >
                    </motion.div>
                </div>
            </div>
            {/* <div className='py-20 bg-white'>
                <div className='max-w-[1200px] mx-auto p-3 w-full'>
                    <section className='flex flex-row lg:flex-nowrap flex-wrap items-center  justify-center w-full gap-5'>
                        <div className='flex flex-col space-y-5 lg:w-[48%] items-center justify-center'>
                            <div className='text-4xl capitalize leading-[50px] font-bold text-black text-center'>Sounds Like Softek Might Be<br /> nThe Right Choice For<br /> YournFinance?</div>
                            <div className='text-4xl leading-7 text-center font-semibold text-[#104cba]'>+1 (888) 123-4567</div>
                        </div>
                        <div className='lg:w-[48%] w-full relative h-full flex flex-col items-center justify-center gap-10'>
                            <img className='w-[517px] h-[521px] object-contain' src={SoundImage} />
                            <img className='absolute  bottom-[90px] lg:left-[20px] left-[140px]' src={WorldClassImage} />
                            <div onClick={() => navigate("/Contact")} className='self-center'>
                                <Button name="Contact Us Today" />
                            </div>
                        </div>
                    </section>
                </div>
            </div> */}

        </div>
    )
}

export default Home;