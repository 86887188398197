import React, { useContext } from 'react';
import BannersComponent from '../Constant/BannersComponent';
import { BottomContent } from '../App';
import { ClientCard } from '../Constant/ClientCard';
import { expertise } from '../utils/ClientData';
import fadeIn from '../variants';
import { motion } from "framer-motion";

function Clients() {
  const UseBottomContext = useContext(BottomContent);

  return (
    <div ref={UseBottomContext} className='flex flex-col  '>
      <BannersComponent header="Our Clients" title="Clients" />
      <div className='py-10 overflow-hidden'>
        <div className='max-w-[1200px] mx-auto p-3'>
          <div className="flex flex-col gap-4">
            <motion.div variants={fadeIn("bottom", 0.2)} initial="hidden" whileInView={"visible"} className='text-lg text-center font-semibold text-[#104cba]'>Our Clients
            </motion.div>
            <motion.div variants={fadeIn("bottom", 0.3)} initial="hidden" whileInView={"visible"} className='text-3xl self-center capitalize font-semibold text-black'>We explore some of the latest trends and strategies
            </motion.div>
            <div  className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5">
              {expertise.map(item => (
                <ClientCard title={item.title} img={item.img} text={item.text} />
              ))}
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}

export default Clients;