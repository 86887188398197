import HistoryImage from '../Assert/Vector077-7.webp';
import RoundIcon from '../Assert/Ban.png';
import { motion } from "framer-motion";
import fadeIn from '../variants';

export const OurHistory = [
    {
        img: HistoryImage,
        company: "Our History",
        title: "Expertise in diverse business verticals",
        description: "Nulla eleifend, lectus eu gravida facilisis, ipsum metus faucibus eros, vitae vulputate nibh libero ac metus. Phasellus magna erat, consectetur nec faucibus at, mollis vitae mauris.",
        descri: [
            {
                img1: RoundIcon,
                header: "Technical Assessment",
                headerDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut elit tellus, luctu adipiscing elit ut elit tellus tellus",
                variants:fadeIn("left", 0.3)
            },
            {
                img1: RoundIcon,
                header: "Joint Collaboration",
                headerDescription: "Consectetur adipiscing adipiscing elit ut elit tellus elit ut elit tellus, luctu lorem ipsum dolor sit amet",
                variants:fadeIn("right", 0.5)
            }
        ]
    },
]