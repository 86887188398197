import fadeIn from '../variants';


export const Experience = [
    {
        title: "Project Finished",
        number: "2,531",
        variants: fadeIn("bottom", 0.3)
    },
    {
        title: "Years Experience",
        number: "12 +",
        variants: fadeIn("top", 0.3)
    },
    {
        title: "Happy Clients",
        number: "280",
        variants: fadeIn("bottom", 0.2)
    },
    {
        title: "Recognition",
        number: "3,587",
        variants: fadeIn("top", 0.4)
    },


]