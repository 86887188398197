import Product1 from '../Assert/Home/icon-02-18.png';
import Product2 from '../Assert/Home/icon-01-18.png';
import Product3 from '../Assert/Home/icon-03-18.png';
import Product4 from '../Assert/Home/icon-04-18.png';
import Product5 from '../Assert/Home/icon-05-18.png';
import Product6 from '../Assert/Home/icon-06-18.png';
import fadeIn from '../variants';

export const RangeOfServicesData = [

    
    {
        img: Product1,
        header: "Web applications development",
        headerDescription: "We develop customized, and domain specific web application to meet your demands cost-effectively.",
        variants: fadeIn("bottom", 0.2)
    },
    {
        img: Product3,
        header: "Mobile application development",
        headerDescription: "Get end-to-end mobile app development from business analysis to deployment.",
        variants: fadeIn("bottom", 0.3)
    },
    {
        img: Product2,
        header: "Custom software development",
        headerDescription: "Get a custom solution developed tailored to your requirements to elevate your business profits.",
        variants: fadeIn("bottom", 0.4)
    },
    {
        img: Product4,
        header: "Games development",
        headerDescription: "Get end-to-end 2D and 3D animated games development we’re designing games based on the requirements and also for the every age",
        variants: fadeIn("bottom", 0.5)
    },
    {
        img: Product5,
        header: "User experience research & design",
        headerDescription: "UX design accounts for half of the app’s success. Get a custom design by our experienced UI/UX experts.",
        variants: fadeIn("bottom", 0.6)
    },
    {
        img: Product6,
        header: "Extended Reality (AR, & VR)",
        headerDescription: "With hands-on expertise in offering innovative AR, & VR solutions such as VR-based training",
        variants: fadeIn("bottom", 0.7)
    },

]