import Product1 from '../Assert/FeaturedServices/Content-Strategy1-7.png';
import Product2 from '../Assert/FeaturedServices/Visual-Interface1-7.png';
import Product3 from '../Assert/FeaturedServices/Creative-Minds-7.png';
import Product4 from '../Assert/FeaturedServices/Engineering-Services-7.png';
import fadeIn from '../variants';

export const FeaturedServicesData = [

    {
        img1: Product1,
        header: "Product Design",
        headerDescription: "Donec fringilla lacus ut ipsum faucibus posuere. Praesent ut lacus ac sem commodo mattis.",
        variants: fadeIn("bottom", 0.5)
    },
    {
        img1: Product2,
        header: "Digital Services",
        headerDescription: "Consectetur adipisicing elit, sed do eiusmod tempor, praesent commodo mattis.",
        variants: fadeIn("top", 0.5)
    },
    {
        img1: Product3,
        header: "Innovative Thinking",
        headerDescription: "Praesent ut lacus ac sem commodo mattis. Dolor sit amet, consectetur",
        variants: fadeIn("bottom", 0.5)
    },
    {
        img1: Product4,
        header: "Content Engineering",
        headerDescription: "Lacus ac sem commodo mattis. Praesent ut lacus ac sem commodo mattis.",
        variants: fadeIn("top", 0.5)
    },



]