import ReactImage from "../Assert/Technologies/react.png";
import PwaImage from "../Assert/Technologies/pwa.png";
import RubyImage from "../Assert/Technologies/ruby.png";
import PythonImage from "../Assert/Technologies/python.png";
import AwsImage from "../Assert/Technologies/aws.png";
import NodeImage from "../Assert/Technologies/node.png";
import AugularImage from "../Assert/Technologies/angular.png";
import AppwordImage from "../Assert/Technologies/angular.png";

export const technologyList = [
    ReactImage, PwaImage, RubyImage, PythonImage, AwsImage, AppwordImage,
    NodeImage, AugularImage
  ];