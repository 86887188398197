import React, { useContext } from 'react';
import { motion } from "framer-motion";
import fadeIn from '../variants';
import BannersComponent from '../Constant/BannersComponent';
import { BottomContent } from '../App';
import { WebDesignData, WebDesigningData } from '../utils/WebDesignData';
import { MobileApplicationsData, MobileData } from '../utils/MobileApplicationsData';
import Button from '../Constant/Button';
import LightDarkBanner from '../Assert/Service/Img_13.webp';
import GetInTouch from '../Assert/vec03-7.png';
import Home5 from '../Assert/Home5-17-7.png';
import Home7 from '../Assert/Home5-15-7.png';
import Banner from '../Assert/Ban.png';

function MobileApplication() {
  const UseBottomContext = useContext(BottomContent);
  return (
    <div ref={UseBottomContext} className='flex flex-col  '>
      <BannersComponent header="Mobile Applications" title="Mobile Apps" />
      <div className='py-16 overflow-hidden'>
        <div className='max-w-[1200px] mx-auto p-3'>
          {MobileData.map((item, index) =>
            <section key={index} className='grid md:grid-cols-2 grid-cols-1 gap-5'>
              <div className='col-span-1'>
                <motion.img variants={fadeIn("left", 0.2)} initial="hidden" whileInView={"visible"} className='w-full h-[90%] object-contain' src={item.img} />
              </div>
              <div className='flex flex-col space-y-5 col-span-1'>
                <motion.div variants={fadeIn("bottom", 0.2)} initial="hidden" whileInView={"visible"} className='text-lg font-semibold text-[#104cba]'>{item.company}</motion.div>
                <motion.div variants={fadeIn("bottom", 0.4)} initial="hidden" whileInView={"visible"} className='text-3xl capitalize font-semibold text-black'>{item.title}</motion.div>
                <motion.p variants={fadeIn("bottom", 0.6)} initial="hidden" whileInView={"visible"} className='text-base text-[#828282]'>{item.description1}</motion.p>
              </div>
            </section>)}
        </div>
      </div>
      <div className='bg-gradient-to-t from-[#F2FAFC] to-[#F2FAFC] overflow-hidden'>
        <div className='max-w-[1200px] mx-auto w-full p-3 py-20'>
          <div className='grid lg:grid-cols-3 grid-cols-1 gap-5'>
            {MobileApplicationsData.map((item, index) =>
              <motion.div key={index} variants={item.variants} initial="hidden" whileInView={"visible"} className=' border border-white col-span-1 flex flex-col space-y-5 p-5 w-full rounded-md bg-white hover:bg-[#103AB1] hover:border-[#103AB1] text-[#103AB1] hover:text-white transition-all duration-200 shadow'>
                <div className='text-5xl'>{item.img}</div>
                <h3 className='text-lg font-semibold'> {item.title}</h3>
                <p>{item.description} </p>
              </motion.div>
            )}
          </div>
        </div>
      </div>
      <div style={{ backgroundImage: `url(${LightDarkBanner})` }} className='bg-no-repeat bg-cover flex flex-col overflow-hidden'>
        {/* <div className='max-w-[1200px] mx-auto p-3 relative'>
            <div className='flex flex-col space-y-10'>
              <div className='flex flex-col items-center justify-center space-y-5'>
                <motion.div variants={fadeIn("bottom", 0.2)} initial="hidden" whileInView={"visible"}  className='text-lg font-semibold text-[#104cba]'>Featured Services</motion.div>
                <motion.div variants={fadeIn("bottom", 0.4)} initial="hidden" whileInView={"visible"}  className='text-3xl capitalize text-center font-semibold text-black'>Engaging Creative minds via technology
                </motion.div>
              </div>
              <FeaturedServicesCard />
              <img className='w-6 h-6 object-contain left-[70%] absolute -bottom-[20px]' src={Banner} />
            </div>
          </div> */}
        <div className='max-w-[1200px] mx-auto p-3 w-full relative flex flex-col gap-10 py-12'>
          <img className='left-[65%] absolute top-0' src={Banner} />
          <div className='flex flex-row lg:flex-nowrap flex-wrap  justify-between w-full gap-5'>
            <div className='lg:w-[46%] w-full'>
              <motion.img variants={fadeIn("left", 0.2)} initial="hidden" whileInView={"visible"} src={GetInTouch} />
            </div>
            <img className='absolute left-[46%]' src={Home5} />
            <div className='flex flex-col lg:w-[48%] w-full space-y-5'>
              <motion.div variants={fadeIn("bottom", 0.3)} initial="hidden" whileInView={"visible"} className='text-lg font-semibold text-[#104cba]'>Get in Touch              </motion.div>
              <motion.div variants={fadeIn("bottom", 0.4)} initial="hidden" whileInView={"visible"} className='text-4xl capitalize font-semibold text-black'>we provide best services  <br />Need Help? </motion.div>
              <motion.div variants={fadeIn("bottom", 0.6)} initial="hidden" whileInView={"visible"} className='flex flex-col space-y-4'>
                <input placeholder='Your Name *' />
                <input placeholder='Your Email *' />
                <textarea className='textarea' placeholder='Messages *' />
                <Button name="Send Message" />
              </motion.div>
            </div>
          </div>
        </div>
        <div className='flex flex-row gap-5'>
          <div className='lg:w-[46%] w-full'>
          </div>
          <div className='lg:w-[46%] w-full flex flex-col items-center justify-center'>
            <img src={Home7} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileApplication;