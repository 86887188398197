import React, { useContext } from 'react'
import { BottomContent } from '../../App';

function PaymentPolicy() {
  const UseBottomContext = useContext(BottomContent);
  return (
    <div ref={UseBottomContext} className='py-10'>
      <div className='max-w-[1200px] mx-auto p-3 flex flex-col gap-3'>
        <div className='text-3xl self-center capitalize font-semibold text-black'>Payment Methods Policy </div>
        <p>All Credits are payable in advance and will be credited to your account on the Website.</p>
        <p>You will be able to Credits you have purchased in exchange for Contacts on the Website, and the Website will tell you how many Credits you will need to use in exchange for each individual Contact.</p>
        <p>Where you wish to use any Subscription Services, you will be required to make payment on the Website at the prices and at the intervals shown for such services.</p>
        <p>All Credit Subscriptions are subject to a minimum term, during which they cannot be cancelled and the number of monthly Credits cannot be reduced. The applicable minimum term will be specified at the point of purchase.</p>
        <p>If you have purchased a Credit Subscription, any unused Credits will be carried over month to month and will never expire. If you wish to increase the number of monthly Credits you receive, you can do so by contacting our Customer Operations team on the contact details above.</p>
        <div className='text-lg font-semibold text-[#104cba]'>Refund Policy

        </div>
        <p>There is no refund avialable based on the privacy policy. For more details contact us at info@illusionspotline.com through email or call us on +91 79047 04039 /

          +91 99522 98666.
        </p>
      </div>
    </div>
  )
}

export default PaymentPolicy