import { LuFileBarChart2 } from 'react-icons/lu';
import MobileApplicationsImage from '../Assert/Service/Mobile.png';
import fadeIn from '../variants';
import { RxGear } from 'react-icons/rx';
import { BsBarChart } from 'react-icons/bs';

export const MobileData= [
    {
        img: MobileApplicationsImage,
        company: "PLANNING",
        title: "Mobile Apps",
        description1: "Illusion Spot Line Platform enables you to build custom applications in the cloud without having to build and maintain your own content management services so you’re able to focus on what you do best – creating experiences that people love. From building intelligent business processes to creating new and engaging digital experiences – you and your team can unlock new business value and differentiate from competitors using the same technology that powers Box, including file viewing, security, collaboration, and much more.",        
    },
]

export const MobileApplicationsData = [
    {
        img: <BsBarChart />,
        title: "IOS Development",
        description: "We can help to manage everything from app designing, frameworks, UI/UX to how to get the login credential back etc.",
        // variants: fadeIn("left", 0.3)
    },
    {
        img: <LuFileBarChart2 />,
        title: "Android development",
        description: "We create some of the most creative Android apps for your smartphones. Our Android Developers have excellent command over Java programming using SDK and NDK.",
        // variants: fadeIn("right", 0.4)
    },
    {
        img: <RxGear />,
        title: "IOT App Development",
        description: "As an innovative and smarter technology partner, we can build IoT based solutions for your business needs which will keep you ahead in this digital age",
        // variants: fadeIn("left", 0.5)
    },
    {
        img: <BsBarChart />,
        title: "IOT",
        description: "The Internet of Things is the quickly emerging network of connected objects that are intelligent to collect and exchange data using embedded sensors. It is gradually becoming part of every characteristic of our lives. Not only it is enhancing our comfort, but they also give us more control to make things easier and personal tasks.",
        // variants: fadeIn("right", 0.3)
    },
    {
        img: <BsBarChart />,
        title: "WEARABLE TECHNOLOGY",
        description: "Wearable Technologies can be worn close to the body, on the body or even in the body. It provides a major prospect for businesses, equally in terms of new markets as well as data collection.Wearable tech includes exciting new technologies, like fitness trackers, wearable cameras, smart watches, heart rate monitors, and GPS tracking devices. One of the major features of wearable technology is its ability to connect to the Internet, enabling data to be exchanged between a network and the device.",
        // variants: fadeIn("left", 0.3)
    },
    {
        img: <LuFileBarChart2 />,
        title: "MOBILE COMMERCE",
        description: "M-commerce (mobile commerce) is the buying and selling of goods and services through wireless handheld devices such as cellular telephone and personal digital assistants (PDAs). M-commerce enables users to access the Internet without needing to find a place to plug in. The range of devices that are enabled for mobile commerce functionality is growing, having expanded in recent years to include smartphones and tablets. M-commerce is mainly based in Wireless Application protocol.",
        // variants: fadeIn("right", 0.4)
    },
    {
        img: <RxGear />,
        title: "ENTERPRISE MESSAGING",
        description: "Enterprise messaging system is an enterprise-standard method that enables a “program-program” message among applications and computers throughout a program. Enterprise messaging is widely used today for integrating various disparate enterprise applications. The system is not dependent on a particular operating system or programming language. Enterprise messaging system processes are facilitated by XML messaging, SOAP and Web services.",
        // variants: fadeIn("left", 0.5)
    },
    {
        img: <BsBarChart />,
        title: "TELEMATIC",
        description: "Telemetry is the automatic measurement and wireless transmission of data from distant sources. In other words, this is an automated communication process, through which measurements with other data are collected at distant or unacceptable points and sent to receiving equipment for monitoring. Telemetry measures and transmits data from sensors in utilities, vehicles, smart meters, electricity, robots, and wildlife in what is commonly called the Internet of Things (IoT)."

    },
    {
        img: <BsBarChart />,
        title: "HEALTH CARE",
        description: "With mobile devices becoming inevitable extensions of individuals, the healthcare industry has embraced mobility to improve, innovate and transform the very way it works. Medical apps can be leveraged to improve daily life and foster positive patient outcomes. Hospitals and patients alike benefit from the power of text, voice, data capture, real time critical data access that modern mobility provides.",
        // variants: fadeIn("left", 0.3)
    },
    {
        img: <LuFileBarChart2 />,
        title: "HOSPITALITY",
        description: "Hospitality app helps you to improve the customer satisfaction by allowing employees to save the time that is spent on the entry of important data. The Hospitality App allows you as an hotelier or hospitality professional to engage with your guests by taking the hassles of data entry away. You can also keep customers updated with important messages.",
        // variants: fadeIn("right", 0.4)
    },
    {
        img: <RxGear />,
        title: "VIDEO SOLUTION",
        description: "Video solution is an online video platform that allows broadcasters, online video streaming websites, and companies to upload, modify, store, manage and stream video content over the Internet. The application provides a video player that allows you to deliver your video contents in many browsers, smartphones, mobile phones and older Flash-supported browsers.",
        // variants: fadeIn("left", 0.5)
    },
    {
        img: <BsBarChart />,
        title: "  CHAT BOTS CHAT AI",
        description: "Artificial Intelligence (AI) dramatically changes business, and chat bots fueled by AI is a potential customer service. Chat bots are essentially programs with a conversational user interface. Typically, customer service chat bots answer questions based on key words. Chat bots excel at collecting customer data from support interactions. The chat bot never sleeps, so it is possible to get 24/7 customer support.",
        // variants: fadeIn("right", 0.3)
    },
    {
        img: <RxGear />,
        title: "GEOFENCING",
        description: "Geo-fencing, is an technological advancement that identifies geographical boundaries in the polygon set. Geo-fencing permits an administrator to set up triggers, so an alert will be sent when a user enters or exits a defined range of limits. Not only, but it is also used in various ways towards the betterment of humanity. With Geo-fencing, you can increase your sales and loyalty, , and this can be done with your CRM data. Additionally, Fastenings can be used to measure offline conversions..",
        // variants: fadeIn("left", 0.5)
    },
    {
        img: <BsBarChart />,
        title: "PUSH NOTIFICATION",
        description: "Push notifications symbolize a great means to communicate with your audience. Send personalized Geo-push notifications to precise users, in order that they keep returning back for your products and services or keep them occupied by sending discount offers time to time. Do not miss the chance to make your customers in business, create and broadcast push notifications across different channels.",
        // variants: fadeIn("right", 0.3)
    },
]