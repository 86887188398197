import React from 'react';
import { motion } from "framer-motion";
import { RangeOfServicesData } from '../utils/RangeOfServicesData';

function RangeOfServicesCard() {
    return (
        <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 gap-y-16 h-full mt-16'>
            {RangeOfServicesData.map((item, index) =>
                <motion.div variants={item.variants} initial="hidden" whileInView={"visible"}  key={index} className='flex flex-col  space-y-4 bg-white element-populated rounded-md relative'>
                    <img className='absolute -top-[20px] left-[43%]' src={item.img} />
                    <div className='text-[#01010] capitalize font-semibold self-center text-center text-lg not-italic'>{item.header}</div>
                    <p>{item.headerDescription}</p>
                </motion.div>
            )}
        </div>
    )
}

export default RangeOfServicesCard;