import React, { useEffect, useState } from 'react';
import ChevronUp from "../Assert/ChevronUp.png"

function Bottom(props) {
    const [showTop, setShowTop] = useState(true)
    useEffect(() => {
        const handle = () => {
            window.pageYOffset > 300 ? setShowTop(true) : setShowTop(false)
        }
        window.addEventListener("scroll", handle)
        return () => {
            window.removeEventListener("scroll", handle)
        }
    }, [])
    return (
        <>
        {showTop && (
                <div onClick={() => props.handleClick()} className='w-[40px] h-[40px] hover:-translate-y-2 transition-all duration-500 cursor-pointer flex flex-col items-center justify-center  shadow-2xl fixed bottom-[20px] right-[20px] rounded-full bg-white border-2 z-50'>
                 <img className='w-5 h-5 object-contain' src={ChevronUp}/>
                </div>)}
        </>

    )
}

export default Bottom