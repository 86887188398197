import React, { useContext, useState } from 'react';
import { AboutCompany } from '../utils/AboutCompany';
import { OurHistory } from '../utils/OurHistory';
import { motion } from "framer-motion";
import fadeIn from '../variants';
import { BusinessGoals } from '../utils/BusinessGoals';
import Banner from '../Assert/Year/Ban21.webp';
import Circle from '../Assert/Year/circle-7.png';
import Vector08 from '../Assert/Year/Vector08.webp';
import Triangle from '../Assert/Year/triangle-7.png';
import ExperienceCard from '../Constant/ExperienceCard';
import { Questions } from '../utils/Questions';
import { AiOutlinePlus } from "react-icons/ai";
import { LuMinus } from "react-icons/lu";
import BannersComponent from '../Constant/BannersComponent';
import { TeamMemberData } from '../utils/TeamMemberData';
import TeamMemberCard from '../Constant/TeamMemberCard';
import { BottomContent } from '../App';


function About() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const onSelectIndex = (index) => {
    if (selectedIndex == index) {
      setSelectedIndex(-1)
    }
    else {
      setSelectedIndex(index)
    }
  }
  const UseBottomContext = useContext(BottomContent);

  return (
    <div ref={UseBottomContext} className='flex flex-col'>
      <BannersComponent header="About Company" title="About" />
      <div className='py-16 overflow-hidden'>
        <div className='max-w-[1200px] mx-auto p-3'>
          {AboutCompany.map((item, index) =>
            <section key={index} className='grid md:grid-cols-2 grid-cols-1 gap-5'>
              <div className='col-span-1'>
                <motion.img variants={fadeIn("left", 0.2)} initial="hidden" whileInView={"visible"}  src={item.img} />
              </div>
              <div className='flex flex-col space-y-5 col-span-1'>
                <motion.div variants={fadeIn("bottom", 0.2)} initial="hidden" whileInView={"visible"}  className='text-lg font-semibold text-[#104cba]'>{item.company}</motion.div>
                <motion.div variants={fadeIn("bottom", 0.4)} initial="hidden" whileInView={"visible"}  className='text-3xl capitalize font-semibold text-black'>{item.title}</motion.div>
                <motion.p variants={fadeIn("bottom", 0.6)} initial="hidden" whileInView={"visible"}  className='text-base text-[#828282]'>{item.description1}</motion.p>
                <motion.p variants={fadeIn("bottom", 0.6)} initial="hidden" whileInView={"visible"}  className='text-base text-[#828282]'>{item.description2}</motion.p>
                <motion.p variants={fadeIn("bottom", 0.6)} initial="hidden" whileInView={"visible"}  className='text-base text-[#828282]'>{item.description3}</motion.p>
              </div>
            </section>)}
        </div>
      </div>
      <div className='bg-gradient-to-t from-[#F2FAFC] to-[#F2FAFC] overflow-hidden'>
        <div className='max-w-[1200px] mx-auto w-full p-3 py-20'>
          <div className='grid lg:grid-cols-3 grid-cols-1 gap-5'>
            {BusinessGoals.map((item, index) =>
              <motion.div variants={item.variants} initial="hidden" whileInView={"visible"}  className='border border-white col-span-1 flex flex-col space-y-5 p-5 w-full rounded-md bg-white hover:bg-[#103AB1] hover:border-[#103AB1]  text-[#103AB1] hover:text-white transition-all duration-200 shadow'>
                <div className='text-5xl'>{item.img}</div>
                <h3 className='text-lg font-semibold'> {item.title}</h3>
                <p>{item.description} </p>
              </motion.div>
            )}
          </div>
        </div>
        {/* <div className='max-w-[1200px] mx-auto w-full p-3 py-10'>
          {OurHistory.map((item, index) =>
            <div key={index} className='flex flex-row lg:flex-nowrap flex-wrap items-center justify-between'>
              <div className='flex flex-col space-y-5 lg:w-[43%] w-full'>
                <motion.div variants={fadeIn("bottom", 0.3)} initial="hidden" whileInView={"visible"}  className='text-lg font-semibold text-[#104cba]'>{item.company}</motion.div>
                <motion.div variants={fadeIn("bottom", 0.4)} initial="hidden" whileInView={"visible"}  className='text-4xl capitalize font-semibold text-black'>{item.title}</motion.div>
                <motion.p variants={fadeIn("bottom", 0.6)} initial="hidden" whileInView={"visible"}  className='text-base text-[#828282]'>{item.description}</motion.p>
                {item.descri.map((item, index) =>
                  <motion.div variants={item.variants} initial="hidden" whileInView={"visible"}  key={index} className='flex flex-row items-start space-x-5'>
                    <img className='object-contain hover:-translate-x-4 transition-all duration-150' src={item.img1} />
                    <div className='flex flex-col space-y-2'>
                      <div className='text-lg text-black font-semibold'>
                        {item.header}
                      </div>
                      <div className='text-base text-[#828282]'>
                        {item.headerDescription}
                      </div>
                    </div>
                  </motion.div>
                )}
              </div>
              <div className='lg:w-[49%] w-full'>
                <motion.img variants={fadeIn("right")} initial="hidden" whileInView={"visible"} viewport={{ amount: 0.0 }} src={item.img} />
              </div>
            </div>
          )}
        </div> */}
      </div>
      <div style={{ backgroundImage: `url(${Banner})` }} className='bg-no-repeat bg-cover bg-center py-12'>
        <div className='max-w-[1200px] mx-auto w-full p-3 flex flex-col  justify-between relative' >
          <ExperienceCard img1={Triangle} img2={Circle} />
        </div>
      </div>
      {/* <div className='py-20 relative flex flex-col items-center justify-center'>
        <div className='max-w-[1200px] mx-auto p-3 w-full flex flex-col  space-y-5'>
          <div className='flex flex-col items-center justify-center space-y-5'>
            <motion.div variants={fadeIn("bottom", 0.2)} initial="hidden" whileInView={"visible"}  className='text-lg font-semibold text-[#104cba]'>Team Member              </motion.div>
            <motion.div variants={fadeIn("bottom", 0.4)} initial="hidden" whileInView={"visible"}  className='text-3xl capitalize font-semibold text-black'>Our Proficient Team</motion.div>
          </div>
          <TeamMemberCard data={TeamMemberData} />
        </div>
      </div> */}
      <div style={{ backgroundImage: `url(${Vector08})` }} className='bg-no-repeat bg-contain bg-left py-10'>
        <div className='max-w-[1200px] mx-auto p-3'>
          {Questions.map((item, index) =>
            <div key={index} className='flex flex-row lg:flex-nowrap flex-wrap items-center justify-between w-full'>
              <div className='lg:w-[44%] w-full'>
              </div>
              <div className='flex flex-col space-y-5 lg:w-[52%] w-full'>
                <motion.div variants={fadeIn("bottom", 0.2)} initial="hidden" whileInView={"visible"}  className='text-lg font-semibold text-[#104cba]'>{item.title}</motion.div>
                <motion.div variants={fadeIn("bottom", 0.4)} initial="hidden" whileInView={"visible"}  className='text-4xl capitalize font-semibold text-black'>{item.description}</motion.div>
                <motion.div variants={fadeIn("bottom", 0.6)} initial="hidden" whileInView={"visible"}   className='flex flex-col gap-4'>
                  {item.query.map((item, index) =>
                    <div className='flex flex-col space-y-3 bg-white shadow-lg'>
                      <div key={index} className={`${selectedIndex == index ? "bg-gradient-to-r text-white text-base border-[#ff3834] font-bold duration-200 space-y-2 from-[#ff3834] to-[#ff7133]" : "bg-white"} flex flex-row showdaColor border-white items-center justify-between p-2 shadow-md`}>
                        <div className={`${selectedIndex == index ? "text-white" : "text-black"} text-lg  font-semibold`}>{item.header}</div>
                        <div onClick={() => onSelectIndex(index)} className={`${selectedIndex == index ? "text-white" : "text-[#ff6033]"} text-xl cursor-pointer p-1`}>{selectedIndex == index ? <LuMinus /> : <AiOutlinePlus />}</div>
                      </div>
                      {selectedIndex == index ? <p className='p-3 '>{item.headerDescription}</p> : null}
                    </div>
                  )}
                </motion.div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default About;