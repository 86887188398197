import React, { createContext, useRef, useState } from 'react'
import Home from './Components/Home';
import Header from './Components/Header';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import About from './Components/About';
import Service from './Components/Service';
import Clients from './Components/Clients';
import Products from './Components/Products';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import Bottom from './Components/Bottom';
import Branding from './Components/Branding';
import WebDesigning from './Components/WebDesigning';
import GameDevelopment from './Components/GameDevelopment';
import MobileApplication from './Components/MobileApplication';
import RefundPolicy from './Components/Policy/RefundPolicy';
import PrivacyPolicy from './Components/Policy/PrivacyPolicy';
import TermsandConditions from './Components/Policy/TermsandConditions';
import PaymentPolicy from './Components/Policy/PaymentPolicy';

export const BottomContent = createContext();

function App() {

  const ref = useRef(null);

  const handleClick = () => {
    if (ref.current) {
      const top = ref.current.getBoundingClientRect().top + window.scrollY - 100; // Adjust for 100px offset
      window.scrollTo({ top, behavior: 'smooth' });
    }
  };
  

  const [showHeader, setShowHeader] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [selectedActive, setSelectedActive] = useState("Home");
  const onClickNavigation = (_route_name) => {
    if (selectedActive == _route_name) {
      setSelectedActive("");
    }
    else {
      setSelectedActive(_route_name);
      if (ref.current) {
        const top = ref.current.getBoundingClientRect().top + window.scrollY - 100; // Adjust for 100px offset
        window.scrollTo({ top, behavior: 'smooth' });
      }
      // ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    setShowHeader(false)
  }

  return (
    <BottomContent.Provider value={ref}>
      <BrowserRouter>
        <Header selectedActive={selectedActive} setSelectedActive={setSelectedActive} showHeader={showHeader} showSearch={showSearch} setShowHeader={setShowHeader} setShowSearch={setShowSearch} onClickNavigationRoute={onClickNavigation} />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/About' element={<About />} />
          <Route path='/Services' element={<Service />} />
          <Route path='/Branding' element={<Branding />} />
          <Route path='/Web_Designing' element={<WebDesigning />} />
          <Route path='/mobile_apps' element={<MobileApplication />} />
          <Route path='/Game_Development' element={<GameDevelopment />} />
          <Route path='/Products' element={<Products />} />
          <Route path='/Clients' element={<Clients />} />
          <Route path='/Contact' element={<Contact />} />
          <Route path='/RefundPolicy' element={<RefundPolicy />} />
          <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
          <Route path='/TermsConditions' element={<TermsandConditions />} />
          <Route path='/PaymentsPolicy' element={<PaymentPolicy />} />
          <Route path='/Contact' element={<Contact />} />
        </Routes>
        <Bottom handleClick={handleClick} />
        <Footer handleClick={handleClick} />
      </BrowserRouter>
    </BottomContent.Provider>
  )
}

export default App;