import React from 'react';
import Logo from "../Image/Home1/main-isllogo-dark.png";
import { HeaderMeneu } from '../utils/HeaderMenu';
import { Link } from 'react-router-dom';
import { HiMenuAlt1 } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import { MdOutlineSearch } from "react-icons/md";
import SearchPage from './SearchPage';

function Header(props) {


  return (
    <div className="w-full bg-white h-20 sticky top-0 z-50 shadow-md backdrop-blur-sm">
      <div className='lg:max-w-[1200px] mx-auto p-3 w-full h-full flex flex-row items-center justify-between'>
        <img src={Logo} className='object-contain h-10' alt='Logo' />
        <div className='lg:flex hidden flex-row items-center space-x-8'>
          {HeaderMeneu.map((header, index) =>
            <div className='relative'>
              <div onClick={() => props.onClickNavigationRoute(header.route)} key={index} className={` font-medium text-base inline-block capitalize transition-all duration-150 cursor-pointer rounded`}>
                <Link to={header.route} className={`${props.selectedActive == header.route ? "text-[#013CA3]" : ""} hover:text-[#013CA3]  transition-all duration-200`}> {header.name}</Link>
              </div>
              {props.selectedActive == header.route && header.subName != null ?
                <div className="p-[10px_20px] w-64 absolute flex flex-col gap-3 mt-8 bg-white rounded-md shadow-lg">
                  {header.subName.map((item1, index) =>
                    <Link to={item1.route} onClick={() => props.onClickNavigationRoute(item1.route)} key={index} className="hover:text-[#013CA3] text-[#adadad] transition-all duration-200 whitespace-nowrap cursor-pointer"> {item1.name}</Link>)}
                </div>
                : null}
            </div>
          )}
          <MdOutlineSearch onClick={() => props.setShowSearch(!props.showSearch)} className='text-2xl text-[#999] cursor-pointer' />
          <button onClick={() => props.onClickNavigationRoute("/Contact")} className='px-8 h-[50px] bg-gradient-to-r text-white text-[17px]  border-[#ff7133] font-semibold block from-[#ff3834] to-[#ff7133]  rounded border'>
            <Link to="/Contact">Contact Us</Link>
          </button>
        </div>
        <div className='flex-row space-x-3 lg:hidden flex'>
          <MdOutlineSearch onClick={() => props.setShowSearch(!props.showSearch)} className='text-2xl text-[#999] cursor-pointer' />
          <div onClick={() => props.setShowHeader(!props.showHeader)} className='cursor-pointer'>
            {props.showHeader ?
              <AiOutlineClose className='text-2xl text-[#999]' /> : <HiMenuAlt1 className='text-2xl text-[#999]' />}
          </div>
        </div>
      </div>
      {props.showHeader ?
        <div className="lg:hidden flex flex-col z-30 space-y-5 bg-white shadow-md w-full  absolute left-0  slideUp h-screen p-4">
          {HeaderMeneu.map((header, index) =>
            <div className='flex flex-col'>
              <div onClick={() => props.onClickNavigationRoute(header.route)} key={index} className={`${props.selectedActive == header.route ? "text-[#013CA3]" : ""} hover:text-[#013CA3] font-medium text-base inline-block capitalize  transition-all duration-150 cursor-pointer rounded`}>
                <Link to={header.route} > {header.name}</Link>
              </div>
              {props.selectedActive == header.route && header.subName != null ?
                <div className="p-2 flex flex-col gap-3">
                  {header.subName.map((item1, index) =>
                    <Link to={item1.route} onClick={() => props.onClickNavigationRoute(item1.route)} key={index} className={`hover:text-[#013CA3] text-[#adadad] transition-all duration-200 whitespace-nowrap cursor-pointer`}> {item1.name}</Link>)}
                </div>
                : null}

            </div>

          )}
          <div onClick={() => props.onClickNavigationRoute("/Contact")} className={`${props.selectedActive == "/Contact" ? "text-[#013CA3]" : ""} hover:text-[#013CA3] font-medium text-base inline-block capitalize  transition-all duration-150 cursor-pointer rounded`}>
            <Link to="/Contact" >Contact Us</Link>
          </div>
        </div> : null}
      <SearchPage showSearch={props.showSearch} setShowSearch={props.setShowSearch} />
    </div>
  );
}

export default Header;
