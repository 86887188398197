import React, { useContext } from 'react'
import { BottomContent } from '../../App';

function TermsandConditions() {
  const UseBottomContext = useContext(BottomContent);

  return (
    <div ref={UseBottomContext} className='py-10'>
      <div className='max-w-[1200px] mx-auto p-3 flex flex-col gap-3'>
        <div className='text-3xl self-center capitalize font-semibold text-black'>Terms & Conditions</div>
        <div className='text-lg font-semibold text-[#104cba]'>Payment Methods
        </div>
        <p>You can use payment methods like Credit Card, Debit Card, Upi or Netbanking for payment.
        </p>
        <div className='text-lg font-semibold text-[#104cba]'>Refund Policy

        </div>
        <p>There is no refund avialable based on the privacy policy. For more details contact us at info@illusionspotline.com through email or call us on +91 79047 04039 /

          +91 99522 98666.
        </p>
        <div className='text-lg font-semibold text-[#104cba]'>What data will be collected?  </div>
        <p>All Credits are payable in advance and will be credited to your account on the Website.</p>
        <div className='text-lg font-semibold text-[#104cba]'>What will we use data for?
        </div>
        <p>We will use the information we collect within the memory game to conduct research on what aspects make something harder or easier to remember.
        </p>
        <p>We will not use the information for any commercial or any other internal purpose other than to provide de-identified game activity and the additional information you share to researchers for purposes of the research. The data associated with your device will be anonymized by 3HLearning as soon as the research is completed, at which point 3HLearningwill no longer retain personal information about you or your device that has been collected through the memory game.
        </p>
        <div className='text-lg font-semibold text-[#104cba]'>With whom will my information be shared?
        </div>
        <p>We may share de-identified game activity and the additional information you share with researchers. The information shared with researchers will not contain any personal information you share directly with us. It may include device level information, which would only be provided only in aggregated and anonym zed form.
        </p>
        <div className='text-lg font-semibold text-[#104cba]'>How will the results be used?

        </div>
        <p>The memory game is designed to measure and track improvement on a memorization task over a series of sessions. Do players’ memorization abilities get better over time? What factors make that more likely?

        </p>
        <div className='text-lg font-semibold text-[#104cba]'>Legal basis


        </div>
        <p>We may process the information gathered from your interaction with the memory game according to our legitimate interest in conducting the research. We may also collect and use the demographic information you may choose to provide based on your prior consent.


        </p>
        <div className='text-lg font-semibold text-[#104cba]'> How can I manage my information?</div>
        <p>You may consult our Privacy Policy for more information on your choices and rights regarding personal data. You may also send us a direct inquiry about your data via our webform or email us at 3hlearningindia@gmail.com.</p>

        <div className='text-lg font-semibold text-[#104cba]'> Social Sharing Info
        </div>
        <p>The App features social sharing which let you share actions you take in the App with other media. As a matter of fact, you must have the minimum age limit that is prescribed by the legislation in the individual jurisdictions to use social sharing features integrated in the App. The use of such features enables sharing of information with friends or public, as per the settings you perform with the entity that provides the social sharing feature.
        </p>
        <p>For more information about the processing carried out by the respective third parties in connection with social sharing features, please visit the privacy policies of the entities that provide these features, which can be found here: Google:</p>
        <p>https://policies.google.com/privacy/update
        </p>
        <p>Facebook: https://www.facebook.com/about/privacy/
        </p>
        <p>Twitter: https://twitter.com/en/privacy
        </p>
        <p>Game Center: https://www.apple.com/legal/internetservices/itunes/gamecenter/
        </p>
        <div className='text-lg font-semibold text-[#104cba]'> Purpose of Personal Data
        </div>
        <p>We will use the information you provide to create a profile of your interests so we can offer events & services based on your preferences. We would like to process your concerns and provide you with services and information, improve the layout and content of the pages of the App and customize them.
        </p>
        <p>We can also carry out research on our users’ tracking of usage data. In addition to that, we can help you share with social networking sites at your request; and disclose to legal and lawful authorities if required to do so by law.
        </p>
        <div className='text-lg font-semibold text-[#104cba]'>Reasons/ Factors on which your data is used:

        </div>
        <p>1. Data is used so that we can give the best experience to clients and improve our products, services, and advertising process.

        </p>
        <p>2. We may collect data regarding customer activities on app and is used to help us provide more useful information to our customers and to understand which parts of our app, products, and services are of most interest.
        </p>
        <p>3. We may collect and store details which may be used to improve the relevancy of results provided by our products and services with marketing strategy. The personal data have been collected in relation to the offer of information society services. Please note that your right to erasure may be limited if the data are necessary for compliance with a legal obligation or for the defense of legal claims.
        </p>
        <div className='text-lg font-semibold text-[#104cba]'>Storage, Security and Sharing Features


        </div>
        <p>Storage: The information that you submit via the app is stored on secured servers and is essential in order to process the information and improve customer’s service. It may be transferred by us to the third parties in certain circumstances. By submitting information via the App, you agree to this storing, processing and/or transfer.

        </p>
        <p>Security: illusion spot line assure to take reasonable measures to help protect information about you. We do not sell, trade, or rent users personal identification information to others.
        </p>
        <p>Sharing: We may share information about you as follows or as otherwise described in this Privacy Notice.
        </p>
        <div className='text-lg font-semibold text-[#104cba]'>Children's Privacy



        </div>
        <p>Our services are not directed to children and we don’t direct them to anyone under 13.
          We abide by the Children’s Online Privacy Protection Act (“COPPA”).
          If a user gets identified as a child under this prescribed age within the Services, Vannala Mobile Apps will not collect, store or use any information of such user.
          illusion spot line also does not allow third party partners to access personally identifiable data from users under 13.
          In addition, we may limit how we collect, use, and store some of the information of EU users between 13 and 16. Moreover, under any prior circumstances, illusion spot line received personal information and discovered that was provided by a child under the age of 13, illusion spot line will promptly delete such personal information in a secure manner.
        </p>
        <div className='text-lg font-semibold text-[#104cba]'>Rights of an Individual        </div>
        <p>
          You have the right to request access into the data that we are processing on you, including information about:
          The purposes of the processing , categories of personal data concerned are used.
          The recipients to whom the personal data will be disclosed.
          The envisaged period for which the personal data will be stored. Furthermore, you have the right to obtain a copy of the personal data undergoing processing. Please note that the access may be restricted due to intellectual property or trade secrets
        </p>
        <div className='text-lg font-semibold text-[#104cba]'>Right To Object        </div>
        <p>
          You have the right to object to your personal data processing on grounds relating to your particular situation. In this case, we will cease the processing unless there are compelling legitimate grounds for the processing which override your interests, rights and freedoms or if the processing is necessary for the establishment, exercise or defense of legal claims. You have the right to object to process of your personal data for direct marketing. We will cease the processing of data for this purpose after objection. Please note that if you exercise this right, your user license to use the App will cease automatically.
        </p>
        <div className='text-lg font-semibold text-[#104cba]'>Right To Rectification And Erasure        </div>
        <p>
          You have right to have inaccurate personal data rectified and erased. Furthermore, you can have your personal data where one of the following grounds applies:
          The personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed.
          If you have withdrawn your consent, or objected to the processing and there are no legitimate grounds The personal data have to be erased for compliance with a legal obligation, or it has been unlawfully processed.
        </p>
        <p>The personal data have been collected in relation to the offer of information society services. Please note that your right to erasure may be limited if the data are necessary for compliance with a legal obligation or for the defense of legal claims.
        </p>
        <div className='text-lg font-semibold text-[#104cba]'>Right To Withdraw Consent        </div>
        <p>
          If we have asked for your consent to our processing of your data, you have the right to withdraw your consent at any time.<br />
          If you withdraw your consent, we will cease processing of the data, unless any legal obligation to keep parts of your data. The withdrawal of your consent does not affect the lawfulness of processing based on your consent before its withdrawal.
        </p>
        <div className='text-lg font-semibold text-[#104cba]'>Push notifications        </div>
        <p>
          We send push notifications or alerts to your device to provide information, updates, & promotional communications, only if you have agreed to such notifications. You can deactivate these notifications by changing your notification settings on your device.
        </p>
        <div className='text-lg font-semibold text-[#104cba]'>Contact Details
        </div>
        <p>
          If you have any questions regarding the Privacy Policy, please contact us at the following email address: 3hlearningindia@gmail.com.
        </p>
      </div>
    </div>
  )
}

export default TermsandConditions