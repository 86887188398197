import www from "../Assert/Clients/www.png";
import fox from "../Assert/Clients/foxstone.png";
import newLife from "../Assert/Clients/newlife.png";
import isc from "../Assert/Clients/lsc.png";
import babi from "../Assert/Clients/babliv.png";
import fourdots from "../Assert/Clients/fourdots.png";
import littlepeeple from "../Assert/Clients/littlepeeple.png";
import sparknet from "../Assert/Clients/sparknet.png";
import srigreen from "../Assert/Clients/srigreen.png";
import senciahealth from "../Assert/Clients/senciahealth.png";
import senciastudio from "../Assert/Clients/senciastudio.png";
import atraders from "../Assert/Clients/atraders.png";
import croplife from "../Assert/Clients/croplife.png";
import qtv from "../Assert/Clients/qtv.png";
import Is from "../Assert/Clients/ls.png";
import oktrips from "../Assert/Clients/oktrips.png";
import vfoce from "../Assert/Clients/vfoce.png";
import aimhi from "../Assert/Clients/aimhi.png";
import maci from "../Assert/Clients/maci.png";
import plus from "../Assert/Clients/30plus.png";
import neroro from "../Assert/Clients/neroro.jpg";
import School from "../Assert/Clients/school.jpg"
import Notebook from "../Assert/Clients/notebook.jpg"
import BigBiking from "../Assert/Clients/big.jpg"
import Pump from "../Assert/Clients/ps.jpg"



export const expertise = [
    {
      title: "School Management",
      img: School,
    },
    {
      title: "Notebook Elite",
      img: Notebook
    },
    {
      title: "Big BIking Commune",
      img: BigBiking
    },
    {
      title: "WWW Technologies",
      img: www
    },
    {
      title: "Pump Surgeon",
      img: Pump
    },
    {
      title: "NERO Ro",
      img: neroro
    },
    {
      title: "WWW Tax Consultant",
      img: www
    },
    {
      title: "Senica Health Studio",
      img: senciahealth
    },
    {
      title: "Sencia Studio",
      img: senciastudio,
    },
    {
      title: "35mm",
      img: Notebook
    },
    {
      title: "New Life Foundation",
      img: newLife
    },
    {
      title: "Fox Stone",
      img: fox
    },
    {
      title: "AIM HI",
      img: aimhi
    },
    {
      title: "Spark Net English",
      img: sparknet
    },
    {
      title: "Mancincons",
      img: maci
    },
    {
      title: "QkTrips",
      img: oktrips
    },
    {
      title: "VFOCUS",
      img: vfoce,
    },
    {
      title: "QTV",
      img: qtv
    },
    {
      title: "Sri Green Production",
      img: srigreen
    },
    {
      title: "4A Traders ",
      img: atraders
    },
    {
      title: "Babilav",
      img: babi
    },
    {
      title: "30Plus Matrimonial",
      img: plus
    },
    {
      title: "Little Peepal",
      img: littlepeeple
    },
    {
      title: "Crop Life Bio ",
      img: croplife
    },
    {
      title: "Four Dots",
      img: fourdots
    },
    {
      title: "Lakshmi Saai",
      img: Is
    },
    {
      title: "Lakshmi Store",
      img: isc
    },

  ]