import React from 'react';
import { Experience } from '../utils/Experience';
import { motion } from "framer-motion";

function ExperienceCard(props) {
    return (
        <section className='grid md:grid-cols-4 grid-cols-1 h-full'>
            <img className='absolute top-[10px] left-[55%]' src={props.img1} />
            {Experience.map((item, index) =>
                <motion.div variants={item.variants} initial="hidden" whileInView={"visible"}  key={index} className='flex flex-col items-center justify-center'>
                    <div className='text-[50px] font-semibold text-white'>{item.number}</div>
                    <div className='text-lg  font-medium text-white'>{item.title}</div>
                </motion.div>
            )}
            <img className='absolute bottom-[10px] left-[25%]' src={props.img2} />
        </section>
    )
}

export default ExperienceCard;